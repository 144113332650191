import { ClipLoader } from "react-spinners";

const FormButton = ({ title, className, loading, onClick, type, disabled }) => {
  return (
    <button
      className={`h-9 xs:h-10 ssm:h-11 bg-primary w-full rounded-sm text-white text-xs font-bold ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
    >
      {loading ? (
        <ClipLoader size={20} loading={loading} color="white" />
      ) : null}

      {!loading ? <>{title}</> : null}
    </button>
  );
};

export default FormButton;
