import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { handleToast } from "../../../utils/functions";

import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useModifyClassSubjectTeacher() {
  const { classId } = useParams();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload, classSubjectId) => {
      setLoading(true);

      try {
        const { data } = await client.patch(
          `/classes/${classId}/subjects/${classSubjectId}/update`,
          payload
        );

        setLoading(false);
        handleToast(data?.message);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [classId]
  );

  return [request, loading];
}
