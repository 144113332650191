import { useCallback, useEffect, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchCalendarEventsByDate(selectedDate) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async (_selectedDate) => {
    setData([]);
    setLoading(true);
    try {
      const res = await client.get(
        `/calendar-event/calendar/date/${_selectedDate}`
      );

      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (selectedDate) {
      request(selectedDate);
    }
  }, [request, selectedDate]);

  return [data, loading];
}
