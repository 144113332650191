import React from "react";

// h-[1122px]
export default class ReportCard extends React.PureComponent {
  render() {
    return (
      <div id="reportCard" className="w-[794px] px-4 py-10 flex flex-col">
        <div className="flex">
          <div className="w-24 h-24">
            <img
              src={this.props.school?.logo}
              alt=""
              className="object-contain w-full h-full"
            />
          </div>

          <div className="flex-1">
            <h1 className="text-center mb-2 text-2xl font-bold capitalize">
              {this.props.school?.name}
            </h1>

            <p className="text-center text-gray-text text-sm capitalize">
              {this.props.school?.address}, {this.props.school?.lga},{" "}
              {this.props.school?.state} state
            </p>

            <div className="flex items-center justify-center gap-4">
              <p className="text-center text-gray-text text-sm">
                Tel: {this.props.school?.phone}, {this.props.school?.phone2}
              </p>
              <p className="text-center text-gray-text text-sm">
                <span className="mr-1">Email:</span>
                {this.props.school?.email}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-1">
          <h2 className="underline text-center mb-3 font-semibold uppercase">
            {this.props?.reportCard?.term} TERM STUDENT'S PERFOMANCE REPORT
          </h2>

          <div className="flex justify-between items-center gap-5 mb-2">
            <div className="flex-1 flex gap-2">
              <span className="text-sm text-gray-text">NAME:</span>
              <p className="text-sm uppercase font-semibold border-b pb-[6px] flex-1 px-3">
                {this.props?.reportCard?.lastName}{" "}
                {this.props?.reportCard?.firstName}
              </p>
            </div>
          </div>

          <div className="flex justify-between items-center gap-5 mb-1">
            <div className="flex-1 flex gap-2">
              <span className="text-sm text-gray-text">CLASS:</span>{" "}
              <p className="text-sm font-semibold border-b pb-[6px] px-3 flex-1">
                {this.props?.reportCard?.category}{" "}
                {this.props?.reportCard?.suffix}
              </p>
            </div>

            <div className="flex-1 flex gap-2">
              <span className="text-sm text-gray-text">SESSION:</span>
              <p className="text-sm font-semibold border-b pb-[6px] px-3 flex-1">
                {this.props?.reportCard?.session}
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 mt-5">
          <div className="col-span-2 grid gap-3">
            {/* Subjects cards */}
            <div className="border pb-1">
              <div className="grid grid-cols-11 h-24 bg-gray-light border-b">
                <h4 className="border-r px-1 py-2 col-span-5 uppercase text-sm flex flex-col items-center justify-end font-bold">
                  subjects
                </h4>

                <div className="border-r px-1 py-2 flex flex-col items-center justify-end">
                  <span className="text-sm font-bold">C.A</span>
                  <p className="text-sm mt-0 font-bold">40</p>
                </div>

                <div className="border-r px-1 py-2 flex flex-col items-center justify-end">
                  <p className="text-sm -rotate-90 w-fit uppercase font-bold">
                    exam
                  </p>
                  <p className="text-sm mt-4 font-bold">60</p>
                </div>

                <div className="border-r px-1 py-2 flex flex-col items-center justify-end">
                  <p className="text-sm -rotate-90 w-fit uppercase font-bold">
                    Total
                  </p>
                  <p className="text-sm mt-4 font-bold">100</p>
                </div>

                <div className="border-r px-1 py-2 flex flex-col items-center justify-end">
                  <p className="text-sm -rotate-90 w-fit uppercase font-bold">
                    grade
                  </p>
                  <p className="text-sm mt-4"></p>
                </div>

                <h4 className="col-span-2 uppercase px-1 py-2 text-sm flex flex-col items-center justify-end font-bold">
                  Remarks
                </h4>
              </div>

              {this.props?.subjects?.map((sub, i) => {
                return (
                  <div className="grid grid-cols-11 border-b" key={i}>
                    <p className="col-span-5 border-r py-2 text-sm px-1 font-medium capitalize">
                      {sub?.name}
                    </p>

                    <p className="border-r py-2 text-sm flex items-center justify-center font-medium">
                      {(Number(sub?.continuousAssessmentOneScore) || 0) +
                        (Number(sub?.continuousAssessmentTwoScore) || 0)}
                    </p>

                    <p className="border-r py-2 text-sm flex items-center justify-center font-medium">
                      {sub?.examScore || 0}
                    </p>

                    <p className="border-r py-2 text-sm flex items-center justify-center font-medium">
                      {sub?.totalScore || 0}
                    </p>

                    <p className="border-r py-2 text-sm flex items-center justify-center font-medium">
                      {this.props?.getGrade(Number(sub?.totalScore))}
                    </p>

                    <p className="col-span-2 py-2 uppercase text-xs flex items-center justify-center font-medium">
                      {this.props?.getRemark(Number(sub?.totalScore))}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="">
            {/* grade scale */}
            <div className="border pb-2">
              <div className="px-2 bg-gray-light py-2 mb-1 border-b">
                <h3 className="text-sm font-bold uppercase">Grade scale</h3>
              </div>

              <p className="text-xs mb-1 font-normal text-black-text px-2">
                75% to 100% = A1 (Excellent)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                70% to 74.99% = B2 (Very Good)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                65% to 69.99% = B3 (Good)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                60% to 64.99% = C4 (Credit)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                55% to 59.99% = C5 (Credit)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                50% to 54.99% = C6 (Credit)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                45% to 49.99% = D7 (Pass)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                40% to 44.99% = E8 (Pass)
              </p>
              <p className="text-xs mb-1 font-normal text-black-text px-2">
                Below 40% = F9 (Fail)
              </p>
            </div>

            {/* Performance rating */}
            <div className="border mt-2">
              <div className="px-2 bg-gray-light py-2 border-b">
                <h3 className="text-sm font-bold uppercase text-center">
                  Performance Summary
                </h3>
              </div>

              <div className="">
                <div className="">
                  <div className="grid grid-cols-5 border-b">
                    <div className="py-2 px-1 border-r col-span-2">
                      <p className="text-xs">Mark obtained</p>
                    </div>
                    <div className="py-2 px-1 border-r">
                      <p className="text-xs font-semibold">
                        {this.props?.reportCard?.obtainedScore}
                      </p>
                    </div>

                    <div className="py-2 px-1 border-r">
                      <p className="text-xs">%tage</p>
                    </div>
                    <div className="py-2 px-1">
                      <p className="text-xs font-semibold">
                        {`${this.props?.getAverage(
                          this.props?.reportCard?.obtainedScore,
                          this.props?.reportCard?.obtainableScore
                        )}%`}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-5 border-b">
                    <div className="py-2 pl-1 border-r col-span-2">
                      <p className="text-xs">Mark obtainable</p>
                    </div>
                    <div className="py-2 px-1 border-r">
                      <p className="text-xs font-semibold">
                        {this.props?.reportCard?.obtainableScore}
                      </p>
                    </div>

                    <div className="py-2 px-1 border-r">
                      <p className="text-xs">Grade</p>
                    </div>
                    <div className="py-2 px-1">
                      <p className="text-xs font-semibold">
                        {this.props?.getGrade(
                          (this.props?.reportCard?.obtainedScore /
                            this.props?.reportCard?.obtainableScore) *
                            100
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-center py-2">
                  <p>
                    {this.props?.getRemark(
                      (this.props?.reportCard?.obtainedScore /
                        this.props?.reportCard?.obtainableScore) *
                        100
                    )}
                  </p>
                </div>

                {this.props?.endSessionRemark ? (
                  <div className="flex items-center justify-center py-2 border-t">
                    <p className="text-sm">{this.props?.endSessionRemark}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* teacher's remark */}
        <div className="mt-5">
          <div className="my-1 flex items-start gap-1 mb-2">
            <span className="text-xs">Teacher's Remark:</span>
            <p className="text-xs italic border-b pb-[6px] flex-1 px-3 min-h-[23px]">
              {this.props?.reportCard?.classTeacherRemark}
            </p>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex items-start gap-3 flex-1">
              <span className="text-xs">Teacher's Name:</span>
              <p className="font-semibold text-sm capitalize border-b pb-[6px] px-3 flex-1 min-h-[23px]">
                {this.props?.reportCard?.classTeacherTitle}{" "}
                {this.props?.reportCard?.classTeacherLastName}{" "}
                {this.props?.reportCard?.classTeacherFirstName}
              </p>
            </div>

            <div className="flex items-start gap-3">
              <span className="text-xs">Date:</span>
              <p className="text-xs italic border-b pb-[6px] flex-1 px-3 min-h-[23px]">
                {this.props?.closingDate}
              </p>
            </div>
          </div>
        </div>

        {/* Principal's remark */}
        <div className="mt-5">
          <div className="flex items-center gap-4">
            <div className="flex items-start gap-3 flex-1">
              <span className="text-xs">Principal's Remark:</span>
              <p className="text-xs italic border-b pb-[6px] flex-1 px-3 min-h-[23px]">
                {this.props?.reportCard?.principalRemark}
              </p>
            </div>

            <div className="flex items-start gap-3">
              <span className="text-xs">Date:</span>
              <p className="text-xs italic border-b pb-[6px] flex-1 px-3 min-h-[23px]">
                {this.props?.closingDate}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-start gap-1 mt-3">
            <span className="text-xs">Vacation Date:</span>
            <p className="text-xs italic border-b pb-[6px] px-5 min-h-[23px] min-w-[200px]">
              {this.props?.vacationDate}
            </p>
          </div>

          <div className="flex items-start gap-1 mt-3">
            <span className="text-xs">Next term begins:</span>
            <p className="text-xs italic border-b pb-[6px] px-5 min-h-[23px] min-w-[200px]">
              {this.props?.nextResumptionDate}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
