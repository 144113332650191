import { useCallback, useState } from "react";
import client from "../client";
import { useParams } from "react-router-dom";
import handleApiError from "../../hooks/handleApiError";
import { useEffect } from "react";

export default function useFetchSchoolClasses() {
  const { schoolId } = useParams();
  const [juniorClasses, setJuniorClasses] = useState(null);
  const [seniorClasses, setSeniorClasses] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await client.get(`/classes?school=${schoolId}`);

      setJuniorClasses(
        data?.data?.filter((c) => c?.category?.startsWith("JSS"))
      );
      setSeniorClasses(
        data?.data?.filter((c) => c?.category?.startsWith("SSS"))
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [schoolId]);

  useEffect(() => {
    request();
  }, [request]);

  return [juniorClasses, seniorClasses, loading, request];
}
