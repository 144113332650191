import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { handleToast } from "../../utils/functions";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useInviteTeacherBySchool() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload, schoolId) => {
      setLoading(true);

      try {
        const { data } = await client.post(
          `/auth/invite/teacher/${schoolId}`,
          payload
        );

        handleToast(data?.message);

        setLoading(false);
        navigate(`/${schoolId}/teachers/invites`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [navigate]
  );

  return [request, loading];
}
