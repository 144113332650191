import { useCallback, useEffect, useState } from "react";
import handleApiError from "../../hooks/handleApiError";
import client from "../client";
import { useDispatch } from "react-redux";
import {
  setJuniorCurriculums,
  setSeniorCurriculums,
} from "../../../redux/slices/curriculum/slice";

export default function useFetchGeneralCurriculum(category) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [curriculums, setCurriculums] = useState([]);

  const request = useCallback(
    async (_category) => {
      try {
        setLoading(true);
        const { data } = await client.get(
          `/curriculum/${_category || category}`
        );

        setCurriculums(data?.data);
        if (_category === "junior") {
          dispatch(setJuniorCurriculums(data?.data));
        }

        if (_category === "senior") {
          dispatch(setSeniorCurriculums(data?.data));
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, category]
  );

  useEffect(() => {
    if (category) request(category);
  }, [request, category]);

  return [loading, curriculums, request];
}
