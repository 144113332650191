import { useCallback, useEffect, useState } from "react";
import handleApiError from "../../hooks/handleApiError";
import client from "../client";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setJuniorSubjects,
  setSeniorSubjects,
} from "../../../redux/slices/school/slice";

export default function useFetchSchoolCurriculum(category) {
  const { schoolId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (_category = category) => {
      try {
        setLoading(true);
        const { data } = await client.get(
          `/curriculum/school/${schoolId}/${_category}`
        );

        if (_category === "junior") {
          dispatch(setJuniorSubjects(data?.data));
        }

        if (_category === "senior") {
          dispatch(setSeniorSubjects(data?.data));
        }

        setLoading(false);
        return true;
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [schoolId, dispatch, category]
  );

  useEffect(() => {
    if (category) request(category);
  }, [request, schoolId, category]);

  return [loading, request];
}
