import React from "react";
import { useSelector } from "react-redux";

import FormButton from "../../form/FormButton";
import { formatCurrency } from "../../../utils/functions";
import useMakePayment from "../../../api/payment/useMakePayment";
import { selectSchoolDetails } from "../../../../redux/slices/school/selectors";
import useFetchSchoolBillingDetails from "../../../api/payment/useFetchSchoolBillingDetails";

export default function MakePayment({ invoice, handleCancel }) {
  const school = useSelector(selectSchoolDetails);
  const { data: billingDetails } = useFetchSchoolBillingDetails(
    school?.schoolId
  );
  const { request: submit, loading } = useMakePayment();

  async function handleSubmit() {
    const isSuccess = await submit({
      invoiceId: invoice?.invoiceId,
      schoolId: school?.schoolId,
    });

    if (isSuccess) {
      handleCancel();
    }
  }

  return (
    <div>
      <div className="mb-5">
        {/* <h3 className="side_page_title">Make Payment</h3> */}

        <div className="mb-4 border-b pb-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.invoiceNumber}
          </h4>

          <p className="text-sm text-greyX11">Invoice Number</p>
        </div>

        <div className="flex items-center justify-between py-2">
          <p className="text-sm text-greyX11">Price</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            ₦{formatCurrency(invoice?.unitPrice)}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2">
          <p className="text-sm text-greyX11">Total Students</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            X{invoice?.quantity}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2">
          <p className="text-sm text-greyX11">Subamount</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            ₦{formatCurrency(invoice?.subAmount)}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2">
          <p className="text-sm text-greyX11">Tax Amount</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            ({invoice?.taxPercentage}%) ₦{formatCurrency(invoice?.taxAmount)}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2 border-t border-b">
          <p className="text-sm text-greyX11">Total Amount</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            ₦{formatCurrency(invoice?.totalAmount)}
          </h4>
        </div>

        <h3 className="font-semibold text-yankee_blue text-base mt-3 pt-2 pb-2">
          Card Details
        </h3>

        <div className="flex items-center justify-between py-2 ">
          <p className="text-sm text-greyX11">Card Name</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {billingDetails?.cardAccountName || "N/A"}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2 ">
          <p className="text-sm text-greyX11">Card Type</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {billingDetails?.cardBrand || "N/A"}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2 ">
          <p className="text-sm text-greyX11">Expiry Date</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {billingDetails?.cardExpiryDate || "N/A"}
          </h4>
        </div>

        <div className="flex items-center justify-between py-2">
          <p className="text-sm text-greyX11">Ending with</p>

          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {billingDetails?.cardLastDigits
              ? `*** *** *** ${invoice?.cardLastDigits}`
              : "N/A"}
          </h4>
        </div>
      </div>

      <FormButton title={"Continue"} loading={loading} onClick={handleSubmit} />

      <p
        className="text-center text-coral_red mt-1 py-3 cursor-pointer"
        onClick={handleCancel}
      >
        Cancel
      </p>
    </div>
  );
}
