import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchTeacherBasicInfo = (teacherId) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);

  const request = useCallback(async (id) => {
    setLoading(true);

    try {
      const { data } = await client.get(`/user/${id}`);

      setDetails(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (teacherId) request(teacherId);
  }, [request, teacherId]);

  return [details, loading];
};

export default useFetchTeacherBasicInfo;
