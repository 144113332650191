import { useCallback, useEffect, useState } from "react";
import client from "../client";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import handleApiError from "../../hooks/handleApiError";
import { setTotalUnreadNotifications } from "../../../redux/slices/notification/slice";

export default function useFetchNotifications(page) {
  const { schoolId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [pagination, setPagination] = useState(null);

  const request = useCallback(
    async (_page = 1) => {
      try {
        setLoading(true);

        const res = await client.get(
          `/notification?recipient=${schoolId}&page=${_page}`
        );
        setNotifications(res.data?.data);
        setPagination(res.data?.pagination);

        const unreadNotifications = res.data?.data?.filter(
          (el) => !el?.isRead
        )?.length;
        dispatch(setTotalUnreadNotifications(unreadNotifications));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, schoolId]
  );

  useEffect(() => {
    if (page && schoolId) request(page);
  }, [request, page, schoolId]);

  return [notifications, pagination, loading];
}
