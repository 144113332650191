import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchClassAttendance() {
  const { classId } = useParams();
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);

  const request = useCallback(
    async (weekNumber, calendarId) => {
      try {
        setLoading(true);

        const res = await client.get(
          `/attendance/class/${classId}?calendarId=${calendarId}&week=${weekNumber}`
        );

        setStudents(res.data?.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [classId]
  );

  return { students, loading, request };
}
