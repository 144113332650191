const TableOverviewCard = ({
  icon,
  label,
  value,
  valueColor,
  valueBackgroundColor,
}) => {
  return (
    <div className="flex items-center gap-3 px-2">
      {icon || null}

      <p className="text-morning_blue text-sm font-medium">{label || ""}</p>

      <div
        className={`py-1 min-w-fit px-1 flex items-center justify-center ${valueBackgroundColor}`}
      >
        <p className={`font-bold text-sm ${valueColor}`}>{value || ""}</p>
      </div>
    </div>
  );
};
export default TableOverviewCard;
