import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUserDetails } from "../../../redux/slices/auth/slice";
import { handleToast } from "../../utils/functions";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useRegisterSchoolOwner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const response = await client.post("/auth/register", payload);

        localStorage.setItem("token", response.data?.data?.token);
        dispatch(setUserDetails(response.data?.data?.user));
        handleToast(response.data?.message);

        setLoading(false);
        navigate(`/register/${response.data?.data?.userId}/school-details`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, navigate]
  );

  return [request, loading];
}
