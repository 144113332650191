import { name } from "./";

const selectSchoolOverview = (state) => state[name].schoolOverview;
const selectClassOverview = (state) => state[name].classOverview;
const selectRecentTeachers = (state) => state[name].recentTeachers;
const selectRecentStudents = (state) => state[name].recentStudents;

export {
  selectClassOverview,
  selectSchoolOverview,
  selectRecentStudents,
  selectRecentTeachers,
};
