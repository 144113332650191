import logoImage from "../../assets/images/logo2.png";

const Logo = () => {
  return (
    <div className="h-10">
      <img src={logoImage} alt="GMF" className="h-full object-contain" />
    </div>
  );
};

export default Logo;
