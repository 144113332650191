import { name } from "./";

const selectSchoolDetails = (state) => state[name].schoolDetails;
const selectCalendar = (state) => state[name].calendar;
const selectJuniorSubjects = (state) => state[name].juniorSubjects;
const selectSeniorSubjects = (state) => state[name].seniorSubjects;

export {
  selectSchoolDetails,
  selectCalendar,
  selectJuniorSubjects,
  selectSeniorSubjects,
};
