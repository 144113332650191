import CalendarEvent from "./CalendarEvent";
import moment from "moment";

const CalendarEvents = ({ calendar }) => {
  if (!calendar) return null;

  return (
    <div className="py-4 px-3 bg-ghost_white rounded-sm">
      <h5>Academic Calendar</h5>

      <div className="bg-white rounded-sm mt-5">
        <CalendarEvent
          date={moment(calendar?.resumptionDate).format("DD/MM/YY")}
          title={"Resumption Date"}
          subtitle={`School resumes ${calendar?.term} term academic session`}
        />

        <CalendarEvent
          date={moment(calendar?.closingDate).format("DD/MM/YY")}
          title={"Closing Date"}
          subtitle={`School closes ${calendar?.term} term academic session`}
        />

        <CalendarEvent
          date={moment(calendar?.vacationStartDate).format("DD/MM/YY")}
          title={"Vacation Begins"}
          subtitle={`Vacation starts for ${calendar?.term} term`}
        />

        <CalendarEvent
          date={moment(calendar?.vacationEndDate).format("DD/MM/YY")}
          title={"Vacation Ends"}
          subtitle={`Vacation Ends for ${calendar?.term} term`}
        />

        <CalendarEvent
          date={moment(calendar?.nextResumptionDate).format("DD/MM/YY")}
          title={"Next Term Begins"}
          subtitle={"The next term resumes"}
        />
      </div>
    </div>
  );
};
export default CalendarEvents;
