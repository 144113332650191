import { useCallback, useEffect, useState } from "react";
import handleApiError from "../../hooks/handleApiError";
import client from "../client";
import { useParams } from "react-router-dom";

export default function useFetchSchoolAnnouncement(page) {
  const { schoolId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);

  const request = useCallback(
    async (_page = 1) => {
      try {
        setLoading(true);

        const res = await client.get(
          `/announcement/school/${schoolId}?page=${_page}`
        );
        setData(res.data?.data);
        setPagination(res.data?.pagination);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [schoolId]
  );

  useEffect(() => {
    request(page);
  }, [request, page]);

  return [data, pagination, loading, request];
}
