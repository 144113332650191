import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TableOverview from "../../shared/components/teachers/TableOverview";
import TeachersTable from "../../shared/components/teachers/TeachersTable";
import useFetchTeachers from "../../shared/api/teachers/useFetchTeachers";
import Pagination from "../../shared/components/Pagination";
import TeacherDetails from "../../shared/components/teachers/TeacherDetails";
import TeacherNavigationBar from "../../shared/components/teachers/TeacherNavigationBar";

const Teachers = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userId = query.get("selected");

  const { schoolId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const [loading, teachers] = useFetchTeachers(schoolId, page, setPagination);

  function closeSideModal() {
    navigate(`/${schoolId}/teachers`);
  }

  return (
    <div className="page">
      <h1 className="page_title mb-5">Teachers</h1>

      <TeacherNavigationBar active={1} />

      <div className="main_content">
        <TableOverview />

        <TeachersTable data={teachers} loading={loading} userId={userId} />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      {userId ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${userId ? "block" : ""}`}>
        <TeacherDetails userId={userId} closeSideModal={closeSideModal} />
      </div>
    </div>
  );
};
export default Teachers;
