import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Logo from "../../../shared/components/Logo";
import FormButton from "../../../shared/components/form/FormButton";
import FormInput from "../../../shared/components/form/FormInput";
import authImage from "../../../assets/images/signup.png";
import SelectInput from "../../../shared/components/form/SelectInput";
import useRegisterSchool from "../../../shared/api/registration/useRegisterSchool";

const SchoolDetails = () => {
  const navigate = useNavigate();
  const { schoolOwnerId } = useParams();

  const [schoolDetails, setSchoolDetails] = useState({
    schoolType: "secondary",
    ownerId: schoolOwnerId,
    country: "Nigeria",
  });

  const [request, loading] = useRegisterSchool();

  async function registerSchoolDetails(e) {
    e.preventDefault();
    await request(schoolDetails);
  }

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className="auth_page_title">Enter your school details</h2>

          <form onSubmit={registerSchoolDetails}>
            <FormInput
              placeholder={"School Name"}
              required
              onChange={(e) =>
                setSchoolDetails({
                  ...schoolDetails,
                  name: e.currentTarget.value,
                })
              }
              className={"mb-5"}
            />

            <FormInput
              placeholder={"School Address"}
              required
              onChange={(e) =>
                setSchoolDetails({
                  ...schoolDetails,
                  address: e.currentTarget.value,
                })
              }
              className={"mb-5"}
            />

            <FormInput
              placeholder={"School Email"}
              required
              onChange={(e) =>
                setSchoolDetails({
                  ...schoolDetails,
                  email: e.currentTarget.value,
                })
              }
              className={"mb-5"}
            />

            <FormInput
              placeholder={"Phone Number"}
              required
              minLength={11}
              maxLength={11}
              onChange={(e) =>
                setSchoolDetails({
                  ...schoolDetails,
                  phone: e.currentTarget.value,
                })
              }
              className={"mb-5"}
            />

            <div className="grid grid-cols-2 gap-5">
              <FormInput
                placeholder={"Country"}
                required
                disabled={true}
                value={schoolDetails?.country}
                className={"mb-5"}
                // onChange={(e) =>
                //   setSchoolDetails({
                //     ...schoolDetails,
                //     country: e.currentTarget.value,
                //   })
                // }
              />

              <SelectInput
                placeholder="State"
                required
                handleSelect={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    state: e.currentTarget.value,
                  })
                }
                options={[
                  { name: "Lagos", value: "Lagos" },
                  { name: "Oyo", value: "Oyo" },
                ]}
              />
            </div>

            <FormInput
              placeholder={"L.G.A"}
              required
              onChange={(e) =>
                setSchoolDetails({
                  ...schoolDetails,
                  lga: e.currentTarget.value,
                })
              }
              className={"mb-5"}
            />

            <FormButton
              title={"Submit"}
              className="mt-10"
              type="submit"
              loading={loading}
            />

            <p
              className="text-yankee_blue text-xs mt-2 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Already have an account?{" "}
              <span className="text-primary">Sign In</span>
            </p>
          </form>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};
export default SchoolDetails;
