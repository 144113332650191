import parse from "html-react-parser";

const AnnouncementDetails = ({ announcement }) => {
  return (
    <div className="p-10">
      <div className="mb-10">
        <p className="text-sm text-dark_electric_blue">
          {new Date(announcement?.createdAt)?.toDateString()}
        </p>

        <h2 className="text-center text-xl uppercase">{announcement?.title}</h2>
      </div>

      <div className="text-sm text-police_blue">
        {parse(announcement?.message)}
      </div>
    </div>
  );
};
export default AnnouncementDetails;
