import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../redux/slices/school/selectors";

import SelectInput from "../../shared/components/form/SelectInput";
import Loader from "../../shared/components/Loader";
import BigCalendarV2 from "../../shared/components/calendar/BigCalendarV2";
import CalendarEvents from "../../shared/components/calendar/CalendarEvents";
import SchoolEvents from "../../shared/components/calendar/SchoolEvents";
import useFetchCalendar from "../../shared/api/calendar/useFetchCalendar";
import useFetchCalendarEvents from "../../shared/api/calendar/events/useFetchCalendarEvents";
import useFetchCalendars from "../../shared/api/calendar/useFetchCalendars";

// TODO: Check sidebar
// TODO: add filter calendar by term and session

const Calendar = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedDate = query.get("date");

  const school = useSelector(selectSchoolDetails);
  const currentCalendar = useSelector(selectCalendar);

  const [calendars, loadingCalendars] = useFetchCalendars(school?.state);
  const [calendar, holidays, loadingCalendar, getCalendar] = useFetchCalendar();

  const [selectedCalendar, setSelectedCalendar] = useState(null);

  const [events] = useFetchCalendarEvents(selectedCalendar?.calendarId);

  useEffect(() => {
    setSelectedCalendar(currentCalendar);
  }, [currentCalendar]);

  useEffect(() => {
    if (selectedCalendar) {
      getCalendar(
        selectedCalendar?.state,
        selectedCalendar?.session,
        selectedCalendar?.term
      );
    }
  }, [selectedCalendar, getCalendar]);

  function formatDates(dates) {
    const formattedList = dates.map((d) => {
      let start = moment(d.startDate).toDate();
      let end = moment(d.endDate).toDate();

      let formatted = {
        id: d.calendarEventId,
        title: d.title,
        start: start,
        end: d.endDate ? end : start,
        color: d.isHoliday ? "#FEE6C9" : "grey",
        allDay: true,
      };

      return formatted;
    });

    return formattedList;
  }

  function handleSelectCalendar(params) {
    const [session, termString] = params?.split(" - ");
    const term = termString?.split(" ")?.[0];

    const found = calendars?.find(
      (el) => el?.session === session && el?.term === term
    );

    setSelectedCalendar(found);
  }

  return (
    <div>
      <div className="page">
        <div className="flex items-center my-5">
          <h1 className="page_title flex-1">Calendar</h1>

          <div className="flex items-center justify-end gap-3 md:w-[200px]">
            {loadingCalendars ? (
              <div className="!h-9 !xs:h-10 !ssm:h-11 w-full flex items-center justify-center">
                <ClipLoader
                  loading={loadingCalendars}
                  color={"#059F8B"}
                  size={20}
                />
              </div>
            ) : (
              <SelectInput
                placeholder={"Session"}
                className="mb-0 w-full"
                handleSelect={(e) =>
                  handleSelectCalendar(e.currentTarget.value)
                }
                options={calendars?.map((el) => ({
                  name: `${el?.session} - ${el?.term} term`,
                }))}
                value={`${selectedCalendar?.session} - ${selectedCalendar?.term} term`}
              />
            )}
          </div>
        </div>

        <div className="flex items-center justify-between py-3 px-5 bg-white border-b rounded-tr-2xl rounded-tl-2xl">
          <div className="flex-1">
            <h3 className="text-lg text-yankee_blue font-bold capitalize">
              {`${selectedCalendar?.state} state ${selectedCalendar?.term} term academic calendar`}
            </h3>
          </div>

          {selectedDate ? (
            <div>
              <p className="text-center text-2xl text-primary">
                {moment(selectedDate).format("DD")}
              </p>
              <p className="text-sm text-primary">
                {moment(selectedDate).format("dddd")}
              </p>
            </div>
          ) : null}
        </div>

        <div className="main_content px-5">
          {loadingCalendar ? <Loader loading={loadingCalendar} /> : null}

          <BigCalendarV2
            startDate={calendar?.resumptionDate}
            endDate={calendar?.closingDate}
            eventList={[...holidays, ...formatDates(events)]}
          />
        </div>
      </div>

      <div className="page_right_bar">
        <SchoolEvents selectedDate={selectedDate} />

        <CalendarEvents calendar={selectedCalendar} />
      </div>
    </div>
  );
};

export default Calendar;
