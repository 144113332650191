import EmptyTable from "../../EmptyTable";
import Loader from "../../Loader";

const SubjectTable = ({
  loading,
  data,
  handleSelectSubject,
  selectedSubject,
}) => {
  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-6 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Subject Name</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">Teacher</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Group</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm"> </h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No subject records found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-6 border-b table_row ${
              selectedSubject?.classSubjectId === item?.classSubjectId
                ? "active_table_row"
                : ""
            }`}
            key={i}
            onClick={() => handleSelectSubject(item)}
          >
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.name}
              </p>
            </div>

            <div className="border-r p-3 col-span-2">
              <p className="text-independence text-sm capitalize">
                {item?.teacherId
                  ? `${item?.teacherTitle || ""} ${
                      item?.teacherLastName || ""
                    } ${item?.teacherFirstName || ""}`
                  : "N/A"}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.group}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {new Date(item?.createdAt)?.toLocaleDateString()}
              </p>
              {/* <button className="flex items-center gap-3">
                <small>Modify</small>
                <FaArrowRightLong className="text-sm" />
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubjectTable;
