import { NavLink, useParams } from "react-router-dom";

export default function EditStudentNavigator({ type }) {
  const { classId, schoolId, studentId } = useParams();

  return (
    <div className="table_wrapper">
      <div className="main_content_header">
        <NavLink to={`/${schoolId}/classes/${classId}`}>
          <button className="main_content_header_btn">Back to Class</button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink
          to={`/${schoolId}/classes/${classId}/student/${studentId}?type=basic`}
        >
          <button
            className={`main_content_header_btn ${
              type === "basic" ? "main_content_header_btn_active" : ""
            }`}
          >
            Basic
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink
          to={`/${schoolId}/classes/${classId}/student/${studentId}?type=guardian`}
        >
          <button
            className={`main_content_header_btn ${
              type === "guardian" ? "main_content_header_btn_active" : ""
            }`}
          >
            Guardian
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink
          to={`/${schoolId}/classes/${classId}/student/${studentId}?type=medical`}
        >
          <button
            className={`main_content_header_btn ${
              type === "medical" ? "main_content_header_btn_active" : ""
            }`}
          >
            Medical
          </button>
        </NavLink>
      </div>
    </div>
  );
}
