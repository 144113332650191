import Loader from "../../../../shared/components/Loader";
import FormButton from "../../../../shared/components/form/FormButton";

const ChooseSubjects = ({
  subjects,
  handleSelectSubject,
  selectedSubjects,
  subjectLoading,
  title,
  loading,
  createReportCard,
}) => {
  return (
    <div className="bg-white pb-4 rounded-md">
      <h5 className="text-independence text-base mb-5">
        {title || "Class Subjects"}
      </h5>

      {subjectLoading ? <Loader /> : null}

      <div>
        {!subjectLoading &&
          subjects?.map((subject, i) => {
            const index = selectedSubjects?.findIndex(
              (sub) => sub?.classSubjectId === subject?.classSubjectId
            );

            return (
              <div
                key={i}
                className={`flex items-center px-3 mb-2 h-11 rounded-sm cursor-pointer ${
                  index >= 0 ? "bg-white border border-primary" : "bg-cultured "
                }`}
                onClick={() => handleSelectSubject(subject)}
              >
                <p
                  className={`text-sm font-semibold capitalize ${
                    index >= 0 ? "text-primary" : "text-yankee_blue"
                  }`}
                >
                  {subject?.name}
                </p>
              </div>
            );
          })}
      </div>

      <FormButton
        title={"Create Report card"}
        loading={loading}
        onClick={createReportCard}
        className={"mt-10"}
      />
    </div>
  );
};
export default ChooseSubjects;
