import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../redux/slices/school/selectors";
import {
  formatDate,
  getDayStringFromDate,
  handleToast,
} from "../../../shared/utils/functions";

import Loader from "../../../shared/components/Loader";
import PageLoader from "../../../shared/components/PageLoader";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import ClassNavigationBar from "../../../shared/components/classes/ClassNavigationBar";
import AttendanceTable from "../../../shared/components/classes/attendance/AttendanceTable";
import StudentAttendance from "../../../shared/components/classes/attendance/StudentAttendance";
import AttendanceOverview from "../../../shared/components/classes/attendance/AttendanceOverview";
import useMarkClassAttendance from "../../../shared/api/classes/attendance/useMarkClassAttendance";
import useFetchClassAttendance from "../../../shared/api/classes/attendance/useFetchClassAttendance";

const Attendance = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studentId = query.get("selected");
  const action = query.get("action");

  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);
  const school = useSelector(selectSchoolDetails);

  /**
  |--------------------------------------------------
  | active calendar week state
  |--------------------------------------------------
  */
  const [week, setWeek] = useState(calendar?.week);

  /**
  |--------------------------------------------------
  | current date state
  |--------------------------------------------------
  */
  const [currentDate, setCurrentDate] = useState(new Date());

  /**
  |--------------------------------------------------
  | Api hook to get class details
  |--------------------------------------------------
  */
  const [classLoading, classDetails] = useFetchClassDetails(classId);

  /**
  |--------------------------------------------------
  | Api hook to get class attendance
  |--------------------------------------------------
  */
  const {
    students,
    loading: attendanceLoading,
    request: fetchClassAttendance,
  } = useFetchClassAttendance();

  /**
  |--------------------------------------------------
  | State to track marked students attendance
  |--------------------------------------------------
  */
  const [markedStudentList, setMarkedStudentList] = useState([]);

  /**
  |--------------------------------------------------
  | Api hook to submit attendance
  |--------------------------------------------------
  */
  const { request: submitAttendanceRequest, loading: submitting } =
    useMarkClassAttendance();

  /**
  |--------------------------------------------------
  | Fetch class attendance when week or calendar changes
  |--------------------------------------------------
  */
  useEffect(() => {
    if (calendar && week) fetchClassAttendance(week, calendar?.calendarId);
  }, [calendar, fetchClassAttendance, week]);

  /**
  |--------------------------------------------------
  | setActiveWeek when calendar changes
  |--------------------------------------------------
  */
  useEffect(() => {
    setWeek(calendar?.week);
  }, [calendar]);

  /**
  |--------------------------------------------------
  | Check if date is in the future
  |--------------------------------------------------
  */
  function checkFutureDate() {
    if (moment().diff(new Date(currentDate)) >= 0) return false;
    else return true;
  }

  /**
  |--------------------------------------------------
  | function to submit marked attendance
  |--------------------------------------------------
  */
  async function handleSubmitAttendance() {
    if (submitting) return;

    if (!markedStudentList?.length)
      return handleToast("Attendance sheet is empty", "warning");

    if (checkFutureDate()) {
      return handleToast("Marking future attendance is restricted", "warning");
    }

    if (week <= 0) {
      return handleToast("Week cannot be less than 1", "warning");
    }

    const isSuccess = await submitAttendanceRequest(classId, {
      schoolId: school?.schoolId,
      attendances: [...markedStudentList],
      calendarId: calendar?.calendarId,
      week: week,
      day: getDayStringFromDate(currentDate),
      date: formatDate(currentDate),
    });

    if (isSuccess) {
      setMarkedStudentList([]);
      fetchClassAttendance(week, calendar?.calendarId);
    }
  }

  /**
  |--------------------------------------------------
  | Increase calendar week only if current week is 
  | less than calendar week
  |--------------------------------------------------
  */
  function increaseWeek() {
    if (week === calendar?.week) return;
    setWeek(week + 1);
  }

  /**
  |--------------------------------------------------
  | decrease calendar week only if current week is
  | greater than 1
  |--------------------------------------------------
  */
  function decreaseWeek() {
    if (week <= 1) return;
    setWeek(week - 1);
  }

  /**
  |--------------------------------------------------
  | get clicked date from resumption date till day clicked
  |--------------------------------------------------
  */
  function getDateFromResumptionDate(dayIndex) {
    if (week === 0) {
      return handleToast("Current calendar week has not started", "warning");
    }

    setMarkedStudentList([]);
    const resumptionDate = new Date(calendar?.resumptionDate);

    /**
    |--------------------------------------------------
    | get total days from calendar week minus the current week
    |--------------------------------------------------
    */
    let totalDaysFromCalendar = (week - 1) * 7;

    /**
    |--------------------------------------------------
    | add dayIndex to totalDaysFromcalendar
    |--------------------------------------------------
    */
    let totalDays = totalDaysFromCalendar + dayIndex;

    /**
    |--------------------------------------------------
    | add totalDays to resumption date
    |--------------------------------------------------
    */
    let selectedDate = resumptionDate.setDate(
      resumptionDate.getDate() + (totalDays - 1)
    );
    /**
    |--------------------------------------------------
    | Update current date state
    |--------------------------------------------------
    */
    setCurrentDate(new Date(selectedDate));
  }

  /**
  |--------------------------------------------------
  | Select student for attendance row
  |--------------------------------------------------
  */
  function handleRowSelect(studentId) {
    navigate(
      `/${schoolId}/classes/${classId}/attendance?selected=${studentId}`
    );
  }

  function closeSideModal() {
    navigate(`/${schoolId}/classes/${classId}/attendance`);
  }

  if (classLoading) return <PageLoader loading={classLoading} />;

  return (
    <div>
      <div className="page">
        <div className="mb-5">
          {/**
            |--------------------------------------------------
            | Page title
            |--------------------------------------------------
            */}
          <h1 className="page_title">
            Attendance{" "}
            {classDetails
              ? `(${classDetails?.category} ${classDetails?.suffix})`
              : null}
          </h1>

          <div className="xs:flex xs:items-center xs:justify-between mt-4">
            <div className="flex items-center gap-2">
              <p className="text-sm ssm:text-lg lg:page_title font-semibold">
                {new Date(currentDate).toDateString()}
              </p>

              <div className="flex items-center bg-white rounded-md">
                <div className="py-1 px-1" onClick={decreaseWeek}>
                  <AiOutlineLeft />
                </div>

                <button className="flex items-center gap-2 h-8 xxs:h-8 ssm:h-9 rounded-md text-primary text-sm s:text-base font-bold">
                  <small>Week {week}</small>
                </button>

                <div className="py-1 px-1" onClick={increaseWeek}>
                  <AiOutlineRight />
                </div>
              </div>
            </div>

            {markedStudentList?.length ? (
              <button
                className="flex items-center justify-center gap-2 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-md text-white px-3 min-w-[100px]"
                onClick={handleSubmitAttendance}
              >
                {submitting ? (
                  <Loader size={20} loading={submitting} color="white" />
                ) : (
                  <small>Submit</small>
                )}
              </button>
            ) : null}
          </div>
        </div>

        <ClassNavigationBar activeIndex={3} />

        <div className="main_content">
          <AttendanceOverview />

          <AttendanceTable
            attendanceSheet={students}
            currentDate={currentDate}
            loading={attendanceLoading}
            markedStudentList={markedStudentList}
            setMarkedStudentList={setMarkedStudentList}
            getDateFromResumptionDate={getDateFromResumptionDate}
            handleRowSelect={handleRowSelect}
            studentId={studentId}
          />
        </div>
      </div>

      {studentId || action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${studentId ? "block" : ""}`}>
        <StudentAttendance
          studentId={studentId}
          closeSideModal={closeSideModal}
        />
      </div>
    </div>
  );
};
export default Attendance;
