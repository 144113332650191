const WeekRow = ({ label, attendance }) => {
  function getTotalDaysPresent(arr) {
    let count = 0;

    if (arr?.monday) count++;
    if (arr?.tuesday) count++;
    if (arr?.wednesday) count++;
    if (arr?.thursday) count++;
    if (arr?.friday) count++;

    return count;
  }

  return (
    <div className="flex items-center justify-between m-3 h-11 px-2 rounded-sm bg-cultured">
      <p className="text-sm text-yankee_blue font-semibold flex-1">{label}</p>

      <div className="h-7 w-7 bg-primary flex items-center justify-center rounded-sm">
        <p className="text-xs text-white font-semibold">
          {getTotalDaysPresent(attendance)}/5
        </p>
      </div>
    </div>
  );
};
export default WeekRow;
