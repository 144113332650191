import { useCallback, useState } from "react";
import handleApiError from "../../hooks/handleApiError";
import client from "../client";
import { handleToast } from "../../utils/functions";

export default function useUpdateAminDetails() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (userId, payload) => {
    try {
      setLoading(true);
      const { data } = await client.patch(`/user/${userId}/basic`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      handleToast(data?.message);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return [request, loading];
}
