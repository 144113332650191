import { useCallback, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchClassReportCards(classId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (session, term) => {
      try {
        setData([]);
        setLoading(true);

        const { data } = await client.get(
          `/archives/class/${classId}/reportCards`,
          {
            params: {
              session,
              term,
            },
          }
        );
        setData(data?.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [classId]
  );

  return [data, loading, request];
}
