import { useCallback, useEffect, useState } from "react";

import handleApiError from "../../hooks/handleApiError";
import client from "../client";

const useFetchStudentBasicInfo = (studentId) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(`/student/${studentId}/basic`);

      setData(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [studentId]);

  useEffect(() => {
    if (studentId) fetchData();
  }, [fetchData, studentId]);

  return [loading, data];
};
export default useFetchStudentBasicInfo;
