import { FiUsers } from "react-icons/fi";
import Loader from "../Loader";
import TableOverviewCard from "../TableOverviewCard";

const TableOverview = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Loader loading={loading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[900px]">
          {/* <div className="px-3">
            <h4 className="text-yankee_blue font-bold text-base capitalize">
              Overview
            </h4>
            <p className="text-sm text-greyX11">Class Teacher</p>
          </div> */}

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Teachers"}
            value={0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Male Teachers"}
            value={0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Female Teachers"}
            value={0}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Pending Invites"}
            value={0}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />
        </div>
      </div>
    </>
  );
};

export default TableOverview;
