import { FiUsers } from "react-icons/fi";
import TableOverviewCard from "../../TableOverviewCard";

const CurriculumTableOverview = ({ handleAddSubject }) => {
  return (
    <div className="table_wrapper">
      <div className="table_header !min-w-[800px]">
        <div className="pl-2 cursor-pointer" onClick={handleAddSubject}>
          <p className="text-yankee_blue font-bold text-base underline">
            Add Subject
          </p>

          {/* {selectedSubject ? (
            <div className="flex items-center gap-2">
              <h4 className="text-yankee_blue font-bold text-base capitalize underline">
                {selectedSubject?.name}
              </h4>

              <AiOutlineDown size={12} />
            </div>
          ) : null} */}
        </div>

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Total Scorecards"}
          value={"-"}
          valueBackgroundColor={"bg-water"}
          valueColor={"text-secondary"}
        />

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Total Subjects"}
          value={"-"}
          valueBackgroundColor={"bg-banana_mania"}
          valueColor={"text-saffron"}
        />
        {/* <div />
        <div /> */}

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Total Subjects"}
          value={"-"}
          valueBackgroundColor={"bg-azureish_white"}
          valueColor={"text-primary"}
        />
      </div>
    </div>
  );
};

export default CurriculumTableOverview;
