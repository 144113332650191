import { useSelector } from "react-redux";

import { selectRecentStudents } from "../../../redux/slices/overview/selectors";

import Avatar from "../Avatar";
import Loader from "../Loader";
import EmptyTable from "../EmptyTable";
import useFetchRecentStudents from "../../api/dashboard/useFetchRecentStudents";

const RecentStudents = () => {
  const students = useSelector(selectRecentStudents);
  const [loading] = useFetchRecentStudents(false);

  return (
    <div className="bg-white px-3 py-4 rounded-sm">
      <h5 className="text-independence text-sm mb-2">Recent Students</h5>

      <div className="mt-5">
        {loading ? <Loader loading={loading} /> : null}

        {!loading && !students?.length ? (
          <EmptyTable message={"Student record not found"} />
        ) : null}

        {!loading &&
          students?.map((student, i) => (
            <div className="flex items-center justify-center mb-5" key={i}>
              <Avatar imageUrl={student?.imageUrl} size={40} />

              <div className="flex-1 ml-3">
                <p className="font-semibold text-yankee_blue text-base capitalize">
                  {student?.lastName || ""} {student?.firstName || ""}
                </p>

                <p className="font-light text-morning_blue text-xs capitalize">
                  {/* {new Date(student?.createdAt).toDateString() || ""} */}
                  {student?.category || ""} {student?.suffix || ""}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecentStudents;
