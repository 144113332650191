import Roles from "../../shared/components/dashboard/Roles";
import upcomingEventImage from "../../assets/images/event-upcoming.png";
import RecentTeachers from "../../shared/components/dashboard/RecentTeachers";
import RecentStudents from "../../shared/components/dashboard/RecentStudents";

import { useSelector } from "react-redux";
import {
  selectClassOverview,
  selectSchoolOverview,
} from "../../redux/slices/overview/selectors";

import SchoolSubjectsStatus from "../../shared/components/dashboard/SchoolSubjectsStatus";
import useFetchSchoolOverview from "../../shared/api/dashboard/useFetchSchoolOverview";
import useFetchClassOverview from "../../shared/api/dashboard/useFetchClassOverview";

const Dashboard = () => {
  const data = useSelector(selectSchoolOverview);
  const classes = useSelector(selectClassOverview);

  const [schoolOverviewLoading] = useFetchSchoolOverview();
  const [classOverviewLoading] = useFetchClassOverview();

  return (
    <div className="z-0">
      <div className="page">
        <h1 className="page_title mb-10">Overview</h1>

        <SchoolSubjectsStatus />

        <div className="grid gap-5 mb-10 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 z-10">
          <Roles
            label={"Total Classes"}
            juniorTotal={classes?.totalJuniorClasses}
            seniorTotal={classes?.totalSeniorClasses}
            total={classes?.totalSeniorClasses + classes?.totalJuniorClasses}
            loading={classOverviewLoading}
          />

          <Roles
            label={"Total Subjects"}
            juniorTotal={data?.totalJuniorSubjects}
            seniorTotal={data?.totalSeniorSubjects}
            total={data?.totalSeniorSubjects + data?.totalJuniorSubjects}
            loading={schoolOverviewLoading}
          />

          <Roles
            label={"Total Students"}
            juniorTotal={classes?.totalJuniorStudents}
            seniorTotal={classes?.totalSeniorStudents}
            total={classes?.totalSeniorStudents + classes?.totalJuniorStudents}
            loading={classOverviewLoading}
          />

          <Roles
            label={"Total Teachers"}
            total={data?.totalTeachers}
            loading={schoolOverviewLoading}
          />
        </div>

        <div className="grid gap-5 sm:grid-cols-2">
          <RecentTeachers />
          <RecentStudents />
        </div>
      </div>

      <div className="page_right_bar">
        <h1 className="side_page_title">Upcoming Events</h1>

        <div className="flex items-center justify-center mt-20">
          <div className="w-[80%]">
            <img
              src={upcomingEventImage}
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
