import { useCallback, useState } from "react";
import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useFetchStudentClassHistory() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);

  const request = useCallback(async ({ studentId, classId, session }) => {
    try {
      setLoading(true);

      const { data } = await client.get("/archives/class-history", {
        params: {
          studentId,
          classId,
          session,
        },
      });

      setResponse(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  //   useEffect(() => {
  //     if (payload?.studentId && payload?.classId) {
  //       request(payload);
  //     }
  //   }, [payload, request]);

  return [response, loading, request];
}
