import { NavLink, useParams } from "react-router-dom";
import { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";

import notificationImage from "../../../assets/images/Character.png";
import Loader from "../../../shared/components/Loader";
import NotificationDetails from "../../../shared/components/notifications/NotificationDetails";
import useFetchNotificationById from "../../../shared/api/notifications/useFetchNotificationById";
import useUpdateNotificationStatus from "../../../shared/api/notifications/useUpdateNotificationStatus";

const Notification = () => {
  const { notificationId, schoolId } = useParams();

  const [notification, loading] = useFetchNotificationById(notificationId);
  const [updateNotificationStatus] = useUpdateNotificationStatus();

  useEffect(() => {
    if (notification) {
      updateNotificationStatus(notificationId);
    }
  }, [notification, updateNotificationStatus, notificationId]);

  return (
    <div>
      <div className="page">
        <div className="flex items-center mb-10">
          <h1 className="page_title flex-1">Notifications</h1>
        </div>

        <div className="main_content_header">
          <NavLink to={`/${schoolId}/notifications`}>
            <div className="ml-5 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer hover:bg-slate-50">
              <BsArrowLeft />
            </div>
          </NavLink>
        </div>

        <div className="main_content">
          {loading ? <Loader loading={loading} /> : null}

          {!loading && !notification ? (
            <div>
              <div className="w-[40%] mx-auto">
                <img
                  src={notificationImage}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>

              <p className="text-center px-[20%]">
                No notification to read. Select a a notification from the right
                bar to view notification content
              </p>
            </div>
          ) : null}

          {!loading && notification ? (
            <NotificationDetails notification={notification} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Notification;
