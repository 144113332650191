import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";
import { formatCurrency } from "../../../utils/functions";
// import EmptyTable from "../../EmptyTable";

const BillingInvoiceTable = ({ loading, data }) => {
  const { schoolId } = useParams();
  const navigate = useNavigate();

  function handleNavigate(params) {
    navigate(`/${schoolId}/settings/billing/invoices/${params?.invoiceId}`);
  }

  return (
    <div className="table_wrapper">
      <div className="">
        {/* table header */}
        <div className="grid grid-cols-5 border-b">
          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Invoice Number</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">Invoice Period</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Amount</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Status</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {/* {!loading && !data?.length ? (
          <EmptyTable
            message={"No teacher record found"}
          />
        ) : null} */}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-5 border-b table_row hover:bg-primary_light_20`}
            key={i}
            onClick={() => handleNavigate(item)}
          >
            <div className="border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.invoiceNumber}
              </p>
            </div>

            <div className="p-3 border-r col-span-2">
              <p className="text-independence text-sm">
                {new Date(item?.paymentDueDate)?.toLocaleDateString()} -{" "}
                {new Date(item?.paymentExpiryDate)?.toLocaleDateString()}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                ₦{formatCurrency(item?.totalAmount)}
              </p>
            </div>

            <div className="border-r p-3">
              <div
                className={`text-independence text-sm ${
                  item?.status === "paid"
                    ? "item_paid"
                    : item?.status === "pending"
                    ? "item_pending"
                    : ""
                }`}
              >
                {item?.status}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BillingInvoiceTable;
