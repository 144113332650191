import { useCallback, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";
import { handleToast } from "../../../utils/functions";

export default function useUpdateStudentBasicInfo() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (studentId, payload) => {
    try {
      setLoading(true);
      const { data } = await client.patch(
        `/student/${studentId}/basic`,
        payload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      handleToast(data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return [request, loading];
}
