import { createSlice } from "@reduxjs/toolkit";

const name = "auth";

export const authSlice = createSlice({
  name,
  initialState: {
    regReference: "",
    userDetails: null,
  },
  reducers: {
    setRegReference: (state, action) => {
      state.regReference = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRegReference, setUserDetails } = authSlice.actions;
const { reducer } = authSlice;

export { reducer, name };
