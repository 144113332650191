import { useState } from "react";
import { useParams } from "react-router-dom";

import { handleToast } from "../../../shared/utils/functions";

import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import useInviteTeacherBySchool from "../../../shared/api/invitation/useInviteTeacherBySchool";
import TeacherNavigationBar from "../../../shared/components/teachers/TeacherNavigationBar";

const NewTeacher = () => {
  const { schoolId } = useParams();

  const [email, setEmail] = useState("");
  const [request, loading] = useInviteTeacherBySchool();

  async function sendInvite() {
    if (!email) return handleToast("Email address is required");

    await request({ email }, schoolId);
  }

  return (
    <div className="page">
      <h1 className="page_title mb-5">Add New Teacher</h1>

      <TeacherNavigationBar active={2} />

      <div className="main_content px-5">
        <h2 className="text-lg text-black font-semibold">Invite Users</h2>
        <p className="text-dark_electric_blue text-sm">
          Invite a teacher to your school to collaborate in your workspace
        </p>

        <div className="max-w-md my-10">
          <FormInput
            placeholder={"Enter teacher email"}
            required
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
          />

          <FormButton
            title={"Send invite"}
            onClick={sendInvite}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default NewTeacher;
