import { configureStore } from "@reduxjs/toolkit";

import { name as auth, reducer as authReducer } from "./slices/auth";
import { name as school, reducer as schoolReducer } from "./slices/school";
import {
  name as notification,
  reducer as notificationReducer,
} from "./slices/notification";
import {
  name as curriculum,
  reducer as curriculumReducer,
} from "./slices/curriculum";
import {
  name as overview,
  reducer as overviewReducer,
} from "./slices/overview";

export default configureStore({
  reducer: {
    [auth]: authReducer,
    [school]: schoolReducer,
    [notification]: notificationReducer,
    [curriculum]: curriculumReducer,
    [overview]: overviewReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActionPaths: [
      //     "payload.createdDate",
      //     "payload.lastModifiedDate",
      //     "payload.startDate",
      //     "payload.endDate",
      //   ],
      //   ignoredPaths: [
      //     "payload.createdDate",
      //     "payload.lastModifiedDate",
      //     "payload.startDate",
      //     "payload.endDate",
      //   ],
      // },
      serializableCheck: false,
    }),
});
