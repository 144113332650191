import { MdOutlineDeleteOutline } from "react-icons/md";

import {
  getGrade,
  getTotalForScorecard,
} from "../../../../shared/utils/functions";

const ScorecardsByTermTableBody = ({
  data,
  handleDeleteScorecard,
  scorecardId,
}) => {
  if (!data?.length) return null;

  return (
    <>
      {data?.map((card, i) => (
        <div
          className={`grid grid-cols-9 border-b ${
            scorecardId === card?.scorecardId ? "active_table_row" : ""
          }`}
          key={i}
        >
          <div className="col-span-3 border-r p-3">
            <p className="text-independence text-sm capitalize">{card?.name}</p>
          </div>

          <div className="border-r p-3">
            <p className="text-independence text-sm">
              {card?.continuousAssessmentOneScore}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm">
              {card?.continuousAssessmentTwoScore}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm capitalize">
              {card?.examScore}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm">
              {getTotalForScorecard(card)}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm">
              {getGrade(getTotalForScorecard(card))}
            </p>
          </div>

          <div className="p-1 flex items-center justify-center">
            <div
              className="text-independence h-8 w-8 rounded-full flex items-center justify-center cursor-pointer hover:bg-coral_red hover:text-white"
              onClick={() => handleDeleteScorecard(card)}
            >
              <MdOutlineDeleteOutline />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ScorecardsByTermTableBody;
