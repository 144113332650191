import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchInvoiceById(invoiceId) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await client.get(`/payment/invoices/${id}`);

      setData(response.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (invoiceId) request(invoiceId);
  }, [request, invoiceId]);

  return { request, loading, data };
}
