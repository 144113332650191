import { FiUsers } from "react-icons/fi";

import Loader from "../../Loader";
import TableOverviewCard from "../../TableOverviewCard";

const AttendanceOverview = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Loader loading={loading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[800px]">
          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Days"}
            value={"-"}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total days marked"}
            value={"-"}
            valueBackgroundColor={"bg-pale_pink"}
            valueColor={"text-coral_red"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Average Attendance"}
            value={"-"}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total students"}
            value={"-"}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};

export default AttendanceOverview;
