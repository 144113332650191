import React from "react";
import {
  formatCurrency,
  formatDateTwo,
  formatToStringDate,
} from "../../../utils/functions";

export default function InvoiceOverview({ invoice, handlePayment }) {
  return (
    <div className="table_wrapper">
      {/**
        |--------------------------------------------------
        | First row - plan type
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3">
        {/**
        |--------------------------------------------------
        | Current plan details
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.invoiceNumber}
          </h4>

          <p className="text-sm text-greyX11">Invoice Number</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing status
        |--------------------------------------------------
        */}
        <div className="px-3">
          {/* <div className="bg-primary/20 px-5 w-[40%] flex items-center justify-center rounded-3xl">
            <p className="text-sm text-primary">Paid</p>
          </div> */}
          <h4
            className={`font-bold text-sm capitalize ${
              invoice?.status === "paid" ? "text-primary" : "text-saffron"
            }`}
          >
            {invoice?.status}
          </h4>

          <p className="text-sm text-greyX11">Payment Status</p>
        </div>

        {/**
        |--------------------------------------------------
        | Payment button
        |--------------------------------------------------
        */}
        {invoice?.status === "pending" ? (
          <div className="px-3 flex items-center" onClick={handlePayment}>
            <button className="bg-primary px-5 flex items-center justify-center rounded-sm h-full">
              <p className="text-sm text-white font-bold">Make Payment</p>
            </button>
          </div>
        ) : null}
      </div>

      {/**
        |--------------------------------------------------
        | Second row - plan billing date info
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5 border-none">
        {/**
        |--------------------------------------------------
        | Billing amount
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {formatCurrency(invoice?.totalAmount)} NGN
          </h4>

          <p className="text-sm text-greyX11">Amount</p>
        </div>

        {/**
        |--------------------------------------------------
        | Due date
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {formatToStringDate(invoice?.paymentDueDate)}
          </h4>

          <p className="text-sm text-greyX11">Due Date</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing Period
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {formatDateTwo(invoice?.paymentDueDate, "/")} -{" "}
            {formatDateTwo(invoice?.paymentExpiryDate, "/")}
          </h4>

          <p className="text-sm text-greyX11">Billing Period</p>
        </div>
      </div>
    </div>
  );
}
