import React from "react";
import { formatCurrency } from "../../../utils/functions";

export default function InvoicePayment({ invoice }) {
  return (
    <div className="table_wrapper">
      <div className="px-3 border-b pb-3">
        <h4 className="text-yankee_blue font-bold text-lg capitalize">
          Payment Details
        </h4>
      </div>

      {/**
        |--------------------------------------------------
        | Second row
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5">
        {/**
        |--------------------------------------------------
        | Subtotal
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm">
            ₦{formatCurrency(invoice?.subAmount)} (₦
            {formatCurrency(invoice?.unitPrice)} x {invoice?.quantity})
          </h4>

          <p className="text-sm text-greyX11">Subtotal</p>
        </div>

        {/**
        |--------------------------------------------------
        | Tax
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            ₦{formatCurrency(invoice?.taxAmount)}
          </h4>

          <p className="text-sm text-greyX11">VAT (7.5%)</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing address line two
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-base capitalize">
            ₦{formatCurrency(invoice?.totalAmount || 0)}
          </h4>

          <p className="text-sm text-greyX11">Total</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Third row
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-1 pt-5">
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm">
            {invoice?.description || ""}
          </h4>

          <p className="text-sm text-greyX11">Description</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Fourth row
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 !pb-0 pt-5 border-none">
        {/**
        |--------------------------------------------------
        | Billing Period
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.cardBrand || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Payment Method</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing address line one
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.cardAccountName || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Card Holder</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing address line two
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.cardLastDigits
              ? `*** *** *** ${invoice?.cardLastDigits}`
              : "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Card Number</p>
        </div>
      </div>
    </div>
  );
}
