import React from "react";
import AcademicSessions from "../../../shared/components/archives/AcademicSessions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetchArchivedStudents from "../../../shared/api/archives/students/useFetchArchivedStudents";
import LeftStudentTable from "../../../shared/components/archives/students/LeftStudentTable";

export default function LeftStudents() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");

  const { schoolId } = useParams();
  const navigate = useNavigate();
  const [data, loading] = useFetchArchivedStudents(session, "left");

  function handleSelectSession(params) {
    navigate(`/${schoolId}/archives/left-students?session=${params?.session}`);
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">Left Students</h1>

        <AcademicSessions
          handleSelect={handleSelectSession}
          session={session}
        />

        <div className="main_content">
          <LeftStudentTable data={data} loading={loading} />
        </div>
      </div>
    </div>
  );
}
