import Loader from "../../Loader";
import { formatCurrency, formatDateTwo } from "../../../utils/functions";
// import EmptyTable from "../../EmptyTable";

const TransactionsTable = ({ loading, data }) => {
  return (
    <div className="table_wrapper">
      <div className="">
        {/* table header */}
        <div className="grid grid-cols-5 border-b">
          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Reference</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Amount</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Channel</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Status</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Date</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {/* {!loading && !data?.length ? (
          <EmptyTable
            message={"No teacher record found"}
          />
        ) : null} */}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-5 border-b table_row hover:bg-primary_light_20`}
            key={i}
          >
            <div className="border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.reference}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                ₦{formatCurrency(item?.amount)}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.channel}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">{item?.status}</p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">
                {formatDateTwo(item?.date, "/")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionsTable;
