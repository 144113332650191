import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchInvitations() {
  const { schoolId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get(`/school/${schoolId}/teachers/invites`);

      setData(response.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [schoolId]);

  useEffect(() => {
    request();
  }, [request]);

  return [data, loading, request];
}
