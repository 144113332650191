import { createSlice } from "@reduxjs/toolkit";

const name = "curriculum";

export const authSlice = createSlice({
  name,
  initialState: {
    juniorCurriculums: [],
    seniorCurriculums: [],
    calendar: null,
  },
  reducers: {
    setJuniorCurriculums: (state, action) => {
      state.juniorCurriculums = action.payload;
    },
    setSeniorCurriculums: (state, action) => {
      state.seniorCurriculums = action.payload;
    },
    setCalendar: (state, action) => {
      state.calendar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJuniorCurriculums, setSeniorCurriculums, setCalendar } =
  authSlice.actions;
const { reducer } = authSlice;

export { reducer, name };
