import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { LuTimerReset } from "react-icons/lu";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

const localizer = momentLocalizer(moment);

const BigCalendarV2 = ({ startDate, endDate, eventList }) => {
  const navigate = useNavigate();
  const { schoolId } = useParams();
  function handleClickDate(params) {
    navigate(`/${schoolId}/calendar?date=${params}`);
  }

  // function handleCancel() {
  //   navigate(`/${schoolId}/calendar`);
  // }
  return (
    <div className="h-[500px] p-5 bg-ghost_white mb-14">
      <Calendar
        className="mb-3 big-calendar"
        startAccessor="start"
        endAccessor="end"
        localizer={localizer}
        defaultDate={startDate}
        events={eventList}
        eventPropGetter={(event) => {
          const eventData = eventList.find((ot) => ot.id === event.id);
          const backgroundColor = eventData && eventData.color;

          return {
            style: {
              backgroundColor,
              height: "1px",
              width: "1px",
            },
            className: "eventPropGetter",
          };
        }}
        views={[Views.MONTH]}
        min={startDate}
        max={endDate}
        messages={{
          next: <AiOutlineCaretRight />,
          previous: <AiOutlineCaretLeft />,
          today: <LuTimerReset />,
        }}
        onNavigate={(selectedDate) => {
          handleClickDate(moment(selectedDate).format("YYYY-MM-DD"));
        }}
      />

      {/* <p
        className="text-center text-sm text-coral_red mt-1 py-3 cursor-pointer"
        onClick={handleCancel}
      >
        Cancel
      </p> */}
    </div>
  );
};

export default BigCalendarV2;
