import { NavLink, useParams } from "react-router-dom";
import Loader from "../../Loader";

const ClassTable = ({
  loading,
  classes,
  getTeacherDetails,
  getTotalStudents,
  label,
}) => {
  const { schoolId } = useParams();

  return (
    <div className="bg-white shadow-sm py-5 mt-10">
      <div className="pb-5 px-3 border-b">
        <h2 className="text-yankee_blue font-semibold text-base">{label}</h2>
      </div>

      {loading ? <Loader loading={loading} /> : null}

      {!loading && !classes ? (
        <div className="grid place-items-center py-5">
          {/* <div className="h-32 w-32 mb-4">
              <img
                src={subjectBookImage}
                alt=""
                className="h-full w-full object-contain"
              />
            </div> */}

          <p className="text-xl font-semibold">Oops!</p>
          <p className="text-sm text-auro_metal_saurus">No data to display</p>
        </div>
      ) : null}

      {!loading && classes ? (
        <div className="overflow-auto max-w-[90vw]">
          <div className="min-w-[700px]">
            {/* table header */}
            <div className="grid grid-cols-4 border-b">
              <div className="border-r p-3">
                <h5 className="font-semibold text-sm">Class</h5>
              </div>

              <div className="border-r p-3">
                <h5 className="font-semibold text-sm">Class Teacher</h5>
              </div>

              <div className="p-3 border-r">
                <h5 className="font-semibold text-sm">Total Students</h5>
              </div>

              <div className="p-3 border-r">
                <h5 className="font-semibold text-sm">Date Created</h5>
              </div>
            </div>

            {/* Table body */}
            {classes?.map((item, i) => (
              <div className="grid grid-cols-4 border-b" key={i}>
                <NavLink
                  to={`/${schoolId}/archives/classes/${item?.classId}/reportcard`}
                >
                  <div className="border-r p-3">
                    <p className="text-primary text-sm cursor-pointer underline">
                      {`${item?.category} ${item?.suffix}`}
                    </p>
                  </div>
                </NavLink>

                <div className="border-r p-3">
                  <p className="text-independence text-sm capitalize">
                    {getTeacherDetails(item)}
                  </p>
                </div>

                <div className="p-3 border-r">
                  <p className="text-independence text-sm">
                    {getTotalStudents(item)}
                  </p>
                </div>

                <div className="p-3 border-r">
                  <p className="text-independence text-sm">
                    {new Date(item?.createdAt)?.toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default ClassTable;
