import { NavLink, useParams } from "react-router-dom";

export default function StudentReportCardNavigatorBar({
  reportCard,
  reportCardLoading,
  action,
}) {
  const { classId, schoolId, studentId } = useParams();

  return (
    <div className="table_wrapper">
      <div className="main_content_header">
        <NavLink to={`/${schoolId}/classes/${classId}/reportcards`}>
          <button className="main_content_header_btn">Back to cards</button>
        </NavLink>

        {!reportCardLoading && !reportCard ? null : (
          <>
            <NavLink
              to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=new-scorecard`}
            >
              <button
                className={`main_content_header_btn ${
                  action === "new-scorecard"
                    ? "main_content_header_btn_active"
                    : ""
                }`}
              >
                Add Scorecard
              </button>
            </NavLink>

            <NavLink
              to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=principal-remark`}
            >
              <button
                className={`main_content_header_btn ${
                  action === "principal-remark"
                    ? "main_content_header_btn_active"
                    : ""
                }`}
              >
                Principal Remark
              </button>
            </NavLink>

            <NavLink
              to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard/${reportCard?.reportCardId}`}
            >
              <button
                className={`main_content_header_btn ${
                  action === "preview-card"
                    ? "main_content_header_btn_active"
                    : ""
                }`}
              >
                Preview Card
              </button>
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}
