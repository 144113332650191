import React from "react";
import { NavLink, useParams } from "react-router-dom";

export default function StudentCategoryNavigationBar({ active, reset }) {
  const { schoolId } = useParams();

  return (
    <div className="table_wrapper">
      <div className="main_content_header">
        <NavLink
          to={`/${schoolId}/archives/graduated-students/junior`}
          onClick={reset}
        >
          <button
            className={`main_content_header_btn ${
              active === "junior" ? "main_content_header_btn_active" : ""
            }`}
          >
            Junior School
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink
          to={`/${schoolId}/archives/graduated-students/senior`}
          onClick={reset}
        >
          <button
            className={`main_content_header_btn ${
              active === "senior" ? "main_content_header_btn_active" : ""
            }`}
          >
            Senior School
          </button>
        </NavLink>
      </div>
    </div>
  );
}
