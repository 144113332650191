import { useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";

import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";
import Subjects from "./Subjects";
import Loader from "../../../../shared/components/Loader";
import FormButton from "../../../../shared/components/form/FormButton";
import useCreateScorecard from "../../../../shared/api/cards/useCreateScorecard";

const NewScorecard = ({
  action,
  reportCard,
  fetchReportCard,
  handleCancelNewScorecard,
}) => {
  const { classId, studentId } = useParams();
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  const [createScorecardRequest, addingScorecard] = useCreateScorecard();
  const [selectedSubject, setSelectedSubject] = useState(null);

  async function createScorecard() {
    if (addingScorecard) return;
    if (!selectedSubject) return toast("No subject selected");
    if (!reportCard) return toast("No report card selected");

    const isSuccess = await createScorecardRequest({
      reportCardId: reportCard?.reportCardId,
      classSubjectId: selectedSubject?.classSubjectId,
      studentId,
    });

    if (isSuccess) {
      setSelectedSubject(null);
      fetchReportCard();
      handleCancelNewScorecard();
    }
  }

  function handleSelectSubject(params) {
    setSelectedSubject(params);
  }

  if (action !== "new-scorecard") return null;

  if (subjectLoading) return <Loader loading={subjectLoading} />;

  return (
    <div>
      <div className="mb-3">
        <h2 className="side_page_title !mb-2 capitalize">New Scorecard</h2>
        <p className="text-xs text-independence">
          Select from subjects below to create a new scorecard
        </p>
      </div>

      <Subjects
        subjects={subjects}
        selectedSubject={selectedSubject}
        handleSelectSubject={handleSelectSubject}
        addingScorecard={addingScorecard}
        createScorecard={createScorecard}
      />

      <FormButton
        title={"Cancel"}
        className={"!bg-transparent !text-coral_red !border-coral_red"}
        onClick={() => {
          setSelectedSubject(null);
          handleCancelNewScorecard();
        }}
      />
    </div>
  );
};

export default NewScorecard;
