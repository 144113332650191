import Loader from "../../Loader";
import FormButton from "../../form/FormButton";

const ChooseSubjectTeacher = ({
  teachers,
  handleSelectTeacher,
  selectedTeacher,
  teachersLoading,
  modifySubjectTeacher,
  updatingTeacher,
}) => {
  return (
    <div className="bg-white pb-4 rounded-md">
      <h5 className="text-independence text-base mb-5">Teachers</h5>

      {teachersLoading ? <Loader /> : null}

      <div className="mb-10">
        {!teachersLoading &&
          teachers?.map((teacher, i) => (
            <div
              key={i}
              className={`flex items-center px-3 mb-3 h-12 rounded-sm cursor-pointer ${
                selectedTeacher?.userId === teacher?.userId
                  ? "bg-primary"
                  : "bg-cultured"
              }`}
              onClick={() => handleSelectTeacher(teacher)}
            >
              <p
                className={`text-sm font-semibold capitalize ${
                  selectedTeacher?.userId === teacher?.userId
                    ? "text-white"
                    : "text-yankee_blue"
                }`}
              >
                {teacher?.lastName} {teacher?.firstName}
              </p>
            </div>
          ))}
      </div>

      <FormButton
        title={"Update subject"}
        onClick={modifySubjectTeacher}
        loading={updatingTeacher}
      />
    </div>
  );
};

export default ChooseSubjectTeacher;
