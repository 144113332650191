import FormButton from "./form/FormButton";

const EmptyTable = ({ image, title, message, onClick, onClickMessage }) => {
  return (
    <div className="grid place-items-center py-5">
      {image ? (
        <div className="h-32 w-32 mb-4">
          <img src={image} alt="" className="h-full w-full object-contain" />
        </div>
      ) : null}

      <p className="text-xl font-semibold">{title || "Oops!"}</p>
      <p className="text-sm text-auro_metal_saurus">
        {message || "Data record not found"}
      </p>

      {onClick ? (
        <FormButton
          onClick={onClick}
          title={onClickMessage || "Refresh"}
          className={"max-w-[200px] mt-5"}
        />
      ) : null}
    </div>
  );
};
export default EmptyTable;
