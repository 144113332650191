import { useCallback, useState } from "react";
import client from "../../client";
import { handleToast } from "../../../utils/functions";
import { useNavigate, useParams } from "react-router-dom";
import handleApiError from "../../../hooks/handleApiError";

export default function useCreateEvent() {
  const navigate = useNavigate();
  const { schoolId } = useParams();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const res = await client.post("/calendar-event", payload);

        handleToast(res.data?.message);
        setLoading(false);

        navigate(`/${schoolId}/calendar?date=${payload?.startDate}`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [schoolId, navigate]
  );

  return [request, loading];
}
