import { NavLink, useParams } from "react-router-dom";
import parse from "html-react-parser";

const AnnouncementRow = ({ announcement }) => {
  const { schoolId } = useParams();
  const date = new Date(announcement?.createdAt);

  return (
    <NavLink to={`/${schoolId}/announcements/${announcement?.announcementId}`}>
      <div className="py-2 px-5 flex items-center gap-2 cursor-pointer">
        <p className="text-xs w-10">{`${date?.getDay()}/${
          date?.getMonth() + 1
        }`}</p>

        <div className="border-2 h-14 rounded-lg border-primary" />

        <div className="flex-1">
          <p className="text-xs text-manatee mb-1">{announcement?.title}</p>
          <div className="text-sm text-police_blue line-clamp-1">
            {parse(announcement?.message)}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default AnnouncementRow;
