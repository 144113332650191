import { createSlice } from "@reduxjs/toolkit";

const name = "overview";

export const overviewSlice = createSlice({
  name,
  initialState: {
    schoolOverview: null,
    classOverview: null,
    recentTeachers: null,
    recentStudents: null,
  },
  reducers: {
    setSchoolOverview: (state, action) => {
      state.schoolOverview = action.payload;
    },
    setClassOverview: (state, action) => {
      state.classOverview = action.payload;
    },
    setRecentTeachers: (state, action) => {
      state.recentTeachers = action.payload;
    },
    setRecentStudents: (state, action) => {
      state.recentStudents = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSchoolOverview,
  setClassOverview,
  setRecentStudents,
  setRecentTeachers,
} = overviewSlice.actions;
const { reducer } = overviewSlice;

export { reducer, name };
