import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchScorecardById(scorecardId) {
  const [loading, setLoading] = useState(false);
  const [scorecard, setScorecard] = useState(null);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);

      const { data } = await client.get(`/result/scorecard/${id}`);

      setScorecard(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (scorecardId) request(scorecardId);
  }, [scorecardId, request]);

  return [scorecard, loading];
}
