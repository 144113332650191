import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchNotificationById(notificationId) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await client.get(`/notification/${id}`);
      setNotification(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (notificationId) request(notificationId);
  }, [request, notificationId]);

  return [notification, loading];
}
