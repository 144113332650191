import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { AiFillEdit, AiTwotoneSave } from "react-icons/ai";
import { ClipLoader } from "react-spinners";
import { FcCancel } from "react-icons/fc";

import { selectUserDetails } from "../../../redux/slices/auth/selectors";

import FormInput from "../../../shared/components/form/FormInput";
import Avatar from "../../../shared/components/Avatar";
import useFetchUserDetails from "../../../shared/api/user/useFetchUserDetails";
import useUpdateAminDetails from "../../../shared/api/user/useUpdateAminDetails";

const AdminDetails = () => {
  const filePickerRef = useRef(null);
  const user = useSelector(selectUserDetails);

  const [updateDetails, loading] = useUpdateAminDetails();
  const [disable, setDisable] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [fetchUserdetails] = useFetchUserDetails();
  const [account, setAccount] = useState({
    phone: "",
    firstName: "",
    email: "",
    profileImage: "",
    lastName: "",
  });

  useEffect(() => {
    setAccount({
      phone: user?.phone || "",
      firstName: user?.firstName || "",
      email: user?.email || "",
      profileImage: user?.profileImage || "",
      lastName: user?.lastName || "",
    });
  }, [user]);

  function resetFilePickerRef() {
    filePickerRef.current.value = "";
    setSelectedFile(null);
    setSelectedImage(null);
  }

  function selectImage(e) {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }

    reader.onload = (readerEvent) => {
      setSelectedImage(readerEvent.target.result);
    };

    setSelectedFile(e.target.files[0]);
  }

  async function submit() {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("firstName", account?.firstName);
    formData.append("lastName", account?.lastName);
    formData.append("email", account?.email);
    formData.append("phone", account?.phone);

    const isSuccess = await updateDetails(user?.userId, formData);
    if (isSuccess) {
      setDisable(true);
      await fetchUserdetails(user?.userId);
    }
  }

  return (
    <div>
      <div className="page">
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Admin Details</h1>
        </div>

        <div className="main_content px-5">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            encType="multipart/form-data"
          >
            <div className="flex items-center justify-center flex-col gap-2 mb-10">
              <Avatar
                imageUrl={selectedImage || user?.profileImage}
                size={80}
              />

              <input
                type={"file"}
                hidden
                name="image"
                ref={filePickerRef}
                onChange={selectImage}
                accept=".jpg, .jpeg, .png"
              />

              <div className="flex gap-2">
                {disable ? null : (
                  <button
                    className="flex items-center text-xs md:text-sm gap-1 border rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold"
                    onClick={() => filePickerRef.current.click()}
                  >
                    <small>Change image</small>
                  </button>
                )}

                {selectedFile && !disable ? (
                  <button
                    className="flex items-center text-xs md:text-sm gap-1 rounded-md px-2 py-1 cursor-pointer bg-white text-red-light border border-red-light font-semibold"
                    onClick={() => resetFilePickerRef()}
                  >
                    <small>Cancel image</small>
                  </button>
                ) : null}
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              <FormInput
                label={"First Name"}
                required
                value={account?.firstName}
                disabled={disable}
                onChange={(e) =>
                  setAccount({ ...account, firstName: e.currentTarget.value })
                }
              />

              <FormInput
                label={"Last Name"}
                required
                value={account?.lastName}
                disabled={disable}
                onChange={(e) =>
                  setAccount({ ...account, lastName: e.currentTarget.value })
                }
              />

              <FormInput
                label={"Email"}
                type="email"
                value={account?.email}
                disabled={true}
                required
                onChange={(e) =>
                  setAccount({ ...account, email: e.currentTarget.value })
                }
              />

              <FormInput
                label={"Phone Number"}
                required
                value={account?.phone}
                disabled={disable}
                maxLength={11}
                onChange={(e) =>
                  setAccount({ ...account, phone: e.currentTarget.value })
                }
              />
            </div>

            <div className="grid md:grid-cols-2 mt-10 gap-4">
              <div className="grid grid-cols-2 gap-4">
                {disable ? (
                  <button
                    className="flex items-center justify-center text-xs md:text-sm gap-2 border rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold h-10"
                    onClick={() => setDisable(false)}
                  >
                    <AiFillEdit color="#fff" size={20} />
                    <small>Edit</small>
                  </button>
                ) : null}

                {!disable ? (
                  <button
                    className="flex items-center justify-center text-xs md:text-sm gap-2 rounded-md px-2 py-1 cursor-pointer bg-white text-red-light border border-red-light font-semibold"
                    onClick={() => {
                      setDisable(true);
                      setSelectedFile(null);
                    }}
                  >
                    <FcCancel />
                    <small>Cancel</small>
                  </button>
                ) : null}

                {!disable ? (
                  <button
                    className="flex items-center justify-center text-xs md:text-sm gap-2 border rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold h-10"
                    onClick={() => submit(false)}
                  >
                    {loading ? (
                      <ClipLoader loading={loading} size={16} color="#fff" />
                    ) : (
                      <>
                        <AiTwotoneSave color="#fff" size={20} />
                        <small>Save Changes</small>
                      </>
                    )}
                  </button>
                ) : null}
              </div>

              <div />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminDetails;
