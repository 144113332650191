import studentAvatar from "../../assets/images/studentavatar.png";
import emptyCalendarImage from "../../assets/images/cuate.png";
import bookImage from "../../assets/images/subjectbook.png";

const IMAGES = {
  studentAvatar,
  emptyCalendarImage,
  bookImage,
};

export default IMAGES;
