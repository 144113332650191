import FormButton from "../form/FormButton";

const NewClass = ({ category, submitting, closeSideModal, createNewClass }) => {
  if (!category) return null;

  return (
    <div className="">
      <div className="">
        <h3 className="side_page_title mb-1">Create New Class</h3>
        <p className="text-sm">
          Do you want to create a new{" "}
          <span className="font-bold">{category}</span> class?
        </p>
      </div>

      <div className="mt-10">
        <FormButton
          title={"Create"}
          loading={submitting}
          onClick={createNewClass}
        />

        <p
          className="text-center text-coral_red mt-1 py-3 cursor-pointer"
          onClick={closeSideModal}
        >
          Cancel
        </p>

        {/* <div className="w-full">
          <button
            className="bg-primary text-white text-xs md:text-sm flex items-center gap-1 h-10 px-2 rounded-md font-semibold cursor-pointer justify-center"
            // onClick={() => createNewClass(newClassRequest)}
          >
            {newClassLoading ? (
              <Loader loading={newClassLoading} />
            ) : (
              <small className="">Confirm</small>
            )}
          </button>

          <button
            className="bg-transparent text-red-light border border-red-light text-xs md:text-sm flex items-center space-x-1 h-10 px-2 rounded-md font-semibold cursor-pointer flex-1 justify-center"
            // onClick={closeNewClassModal}
          >
            <small className="">Cancel</small>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default NewClass;
