const CalendarEvent = ({ date, title, subtitle, onClick }) => {
  function handleClick() {
    if (onClick) onClick();
  }

  return (
    <div
      className="py-2 px-1 flex items-center gap-1 cursor-pointer mb-2"
      onClick={handleClick}
    >
      <p className="text-xs w-14 line-clamp-1">{date || ""}</p>

      <div className={`border-2 h-14 rounded-lg ${"border-primary"}`} />

      <div className="flex-1 ml-1">
        <p className="text-xs text-manatee mb-1 line-clamp-1">{title || ""}</p>
        <p className="text-sm text-police_blue line-clamp-2">
          {subtitle || ""}
        </p>
      </div>
    </div>
  );
};
export default CalendarEvent;
