import { useSelector } from "react-redux";

import { selectRecentTeachers } from "../../../redux/slices/overview/selectors";

import Avatar from "../Avatar";
import Loader from "../Loader";
import EmptyTable from "../EmptyTable";
import useFetchRecentTeachers from "../../api/dashboard/useFetchRecentTeachers";

const RecentTeachers = () => {
  const teachers = useSelector(selectRecentTeachers);

  const [loading] = useFetchRecentTeachers();

  return (
    <div className="bg-white px-3 py-4 rounded-sm">
      <h5 className="text-independence text-sm mb-2">Recent Teachers</h5>

      <div className="mt-5">
        {loading ? <Loader loading={loading} /> : null}

        {!loading && !teachers?.length ? (
          <EmptyTable message={"Teacher record not found"} />
        ) : null}

        {!loading &&
          teachers?.map((teacher, i) => (
            <div className="flex items-center justify-center mb-5" key={i}>
              <Avatar imageUrl={teacher?.imageUrl} size={40} />

              <div className="flex-1 ml-3">
                <p className="font-semibold text-yankee_blue text-base capitalize">
                  {teacher?.lastName || ""} {teacher?.firstName || ""}
                </p>

                <p className="font-light text-morning_blue text-xs capitalize">
                  {teacher?.phone || ""}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecentTeachers;
