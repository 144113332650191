import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { setRecentStudents } from "../../../redux/slices/overview/slice";

import handleApiError from "../../hooks/handleApiError";
import client from "../client";

export default function useFetchRecentStudents() {
  const { schoolId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(async () => {
    dispatch(setRecentStudents(null));

    setLoading(true);
    try {
      const { data } = await client.get(
        `/overview/school/${schoolId}/recentStudents`
      );
      dispatch(setRecentStudents(data?.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [dispatch, schoolId]);

  useEffect(() => {
    request();
  }, [request]);

  return [loading];
}
