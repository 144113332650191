import React from "react";
import { NavLink, useParams } from "react-router-dom";

export default function BillingNavigationBar({ activeIndex }) {
  const { schoolId } = useParams();

  return (
    <div className="table_wrapper">
      <div className="main_content_header">
        <NavLink to={`/${schoolId}/settings/billing`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 0 ? "main_content_header_btn_active" : ""
            }`}
          >
            Overview
          </button>
        </NavLink>

        <NavLink to={`/${schoolId}/settings/billing/transactions`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 1 ? "main_content_header_btn_active" : ""
            }`}
          >
            Transactions
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink to={`/${schoolId}/settings/billing/invoices`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 2 ? "main_content_header_btn_active" : ""
            }`}
          >
            Invoices
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        {/* {activeIndex === 1 ? (
          <button
            className={`main_content_header_btn ${activeIndex === 1 ? "" : ""}`}
          >
            Download as PDF
          </button>
        ) : null} */}
      </div>
    </div>
  );
}
