import { BsFillCircleFill } from "react-icons/bs";
import Loader from "../Loader";

const Roles = ({ label, total, seniorTotal, juniorTotal, loading }) => {
  return (
    <div className="bg-white px-3 py-3 rounded-sm">
      <h5 className="text-independence text-sm mb-2">{label}</h5>

      <div className="h-[50px] flex items-end justify-between">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <p className="text-yankee_blue text-3xl font-bold">{total || 0}</p>
        )}

        <div className="">
          {juniorTotal >= 0 ? (
            <div className="flex items-center gap-2 mb-1">
              <BsFillCircleFill className="text-xxxs text-primary" />
              <p className="text-xs text-auro_metal_saurus">
                Junior Class:{" "}
                <span className="font-bold text-yankee_blue">
                  {juniorTotal}
                </span>
              </p>
            </div>
          ) : null}

          {seniorTotal >= 0 ? (
            <div className="flex items-center gap-2">
              <BsFillCircleFill className="text-xxxs text-secondary" />
              <p className="text-xs text-auro_metal_saurus">
                Senior Class:{" "}
                <span className="font-bold text-yankee_blue">
                  {seniorTotal}
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Roles;
