import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetEmailSent from "./pages/auth/ResetEmailSent";
import VerifyPasswordReset from "./pages/auth/VerifyPasswordReset";
import ResetPassword from "./pages/auth/ResetPassword";
import VerifyEmail from "./pages/auth/VerifyEmail";
import Container from "./shared/components/Container";
import Dashboard from "./pages/Dashboard";
import Teachers from "./pages/Teachers";
import Classes from "./pages/Classes";
import Calendar from "./pages/Calendar";
import Settings from "./pages/Settings";
import Announcements from "./pages/Announcements";
import Notifications from "./pages/Notifications";
import Announcement from "./pages/Announcements/Announcement";
import Notification from "./pages/Notifications/Notification";
import ClassDetails from "./pages/Classes/ClassDetails";
import ClassSubjects from "./pages/Classes/ClassSubjects";
import Attendance from "./pages/Classes/Attendance";
import Scorecards from "./pages/Classes/Scorecards";
import ReportCards from "./pages/Classes/ReportCards";
import NewStudent from "./pages/Classes/NewStudent";
import NewTeacher from "./pages/Teachers/NewTeacher";
import PendingInvites from "./pages/Teachers/PendingInvites";
import StudentReportCard from "./pages/Classes/StudentReportCard";
import EditStudent from "./pages/Classes/EditStudent";
import StudentPreviewReportCard from "./pages/Classes/StudentPreviewReportCard";
import AdminDetails from "./pages/Settings/AdminDetails";
import JuniorCurriculums from "./pages/Settings/curriculums/JuniorCurriculums";
import SeniorCurriculums from "./pages/Settings/curriculums/SeniorCurriculums";
import OwnerDetails from "./pages/auth/Registration/OwnerDetails";
import SchoolProfile from "./pages/Settings/SchoolProfile";
import SchoolDetails from "./pages/auth/Registration/SchoolDetails";
import NewEvent from "./pages/Calendar/NewEvent";
import EditEvent from "./pages/Calendar/EditEvent";
import ClassListForReportcards from "./pages/Archives/reportcards/ClassListForReportcards";
import GraduatedStudents from "./pages/Archives/students/GraduatedStudents";
import LeftStudents from "./pages/Archives/students/LeftStudents";
import ArchivedReportcards from "./pages/Archives/reportcards/ArchivedReportcards";
import ArchivedReportcardDetails from "./pages/Archives/reportcards/ArchivedReportcardDetails";
import Billing from "./pages/Settings/Billings";
import BillingInvoices from "./pages/Settings/BillingInvoices";
import BillingInvoice from "./pages/Settings/BillingInvoice";
import Transactions from "./pages/Settings/Transactions";
import ChoosePlan from "./pages/auth/Registration/ChoosePlan";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="login" element={<Login />} />

        <Route path="register/personal-details" element={<OwnerDetails />} />
        <Route
          path="register/:schoolOwnerId/school-details"
          element={<SchoolDetails />}
        />
        <Route path="register/:schoolId/plan" element={<ChoosePlan />} />

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password/sent" element={<ResetEmailSent />} />
        <Route path="password/reset/:token" element={<VerifyPasswordReset />} />
        <Route path="password/change/:token" element={<ResetPassword />} />
        <Route path="verify/email/:userId/:token" element={<VerifyEmail />} />

        <Route element={<Container />}>
          <Route path=":schoolId/dashboard" element={<Dashboard />} />

          {/* Teachers */}
          <Route path=":schoolId/teachers" element={<Teachers />} />
          <Route path=":schoolId/teachers/new" element={<NewTeacher />} />
          <Route
            path=":schoolId/teachers/invites"
            element={<PendingInvites />}
          />

          {/* Classes */}
          <Route path=":schoolId/classes" element={<Classes />} />
          <Route path=":schoolId/classes/:classId" element={<ClassDetails />} />
          <Route
            path=":schoolId/classes/:classId/students/:studentId/reportcard"
            element={<StudentReportCard />}
          />
          <Route
            path=":schoolId/classes/:classId/students/:studentId/reportcard/:reportcardId"
            element={<StudentPreviewReportCard />}
          />
          <Route
            path=":schoolId/classes/:classId/subjects"
            element={<ClassSubjects />}
          />
          <Route
            path=":schoolId/classes/:classId/attendance"
            element={<Attendance />}
          />
          <Route
            path=":schoolId/classes/:classId/scorecards"
            element={<Scorecards />}
          />
          <Route
            path=":schoolId/classes/:classId/reportcards"
            element={<ReportCards />}
          />
          <Route
            path=":schoolId/classes/:classId/students/new"
            element={<NewStudent />}
          />
          <Route
            path=":schoolId/classes/:classId/student/:studentId"
            element={<EditStudent />}
          />

          {/* Archives */}
          <Route
            path=":schoolId/archives/classes"
            element={<ClassListForReportcards />}
          />
          <Route
            path=":schoolId/archives/classes/:classId/reportcard"
            element={<ArchivedReportcards />}
          />
          <Route
            path=":schoolId/archives/classes/:classId/reportcard/:reportCardId"
            element={<ArchivedReportcardDetails />}
          />
          <Route
            path=":schoolId/archives/graduated-students/:category"
            element={<GraduatedStudents />}
          />
          <Route
            path=":schoolId/archives/left-students"
            element={<LeftStudents />}
          />

          {/* Calendar */}
          <Route
            path=":schoolId/calendar/event/new/:date"
            element={<NewEvent />}
          />
          <Route
            path=":schoolId/calendar/event/edit/:eventId"
            element={<EditEvent />}
          />
          <Route path=":schoolId/calendar" element={<Calendar />} />

          {/* Announcements */}
          <Route
            path=":schoolId/announcements/:announcementId"
            element={<Announcement />}
          />
          <Route path=":schoolId/announcements" element={<Announcements />} />

          {/* Notifications */}
          <Route
            path=":schoolId/notifications/:notificationId"
            element={<Notification />}
          />
          <Route path=":schoolId/notifications" element={<Notifications />} />

          {/* Settings */}
          <Route path=":schoolId/settings" element={<Settings />} />
          <Route path=":schoolId/settings/billing" element={<Billing />} />
          <Route
            path=":schoolId/settings/billing/invoices"
            element={<BillingInvoices />}
          />
          <Route
            path=":schoolId/settings/billing/invoices/:invoiceId"
            element={<BillingInvoice />}
          />
          <Route
            path=":schoolId/settings/billing/transactions"
            element={<Transactions />}
          />
          <Route path=":schoolId/settings/admin" element={<AdminDetails />} />
          <Route path=":schoolId/settings/school" element={<SchoolProfile />} />
          <Route
            path=":schoolId/settings/curriculum/junior"
            element={<JuniorCurriculums />}
          />
          <Route
            path=":schoolId/settings/curriculum/senior"
            element={<SeniorCurriculums />}
          />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
