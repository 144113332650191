import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import ChooseSubjectTeacher from "./ChooseSubjectTeacher";
import useFetchTeachers from "../../../api/teachers/useFetchTeachers";
import useModifyClassSubjectTeacher from "../../../api/classes/subjects/useModifyClassSubjectTeacher";

const ModifyClassSubject = ({
  selectedSubject,
  setSelectedSubject,
  fetchSubjects,
}) => {
  const { schoolId } = useParams();
  const calendar = useSelector(selectCalendar);

  const [teachersLoading, teachers] = useFetchTeachers(schoolId);

  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [modifySubjectTeacherRequest, updatingTeacher] =
    useModifyClassSubjectTeacher();

  async function modifySubjectTeacher() {
    if (!selectedSubject) return;

    await modifySubjectTeacherRequest(
      {
        teacherId: selectedTeacher?.userId,
        session: calendar?.session,
        term: calendar?.term,
      },
      selectedSubject?.classSubjectId
    );

    setSelectedSubject(null);
    setSelectedTeacher(null);
    fetchSubjects();
  }

  if (!selectedSubject) return null;

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h6 className="text-black-text font-semibold capitalize">
          {selectedSubject?.name}
        </h6>
      </div>

      <div className="mb-10">
        <p className="text-sm text-independence">Current teacher</p>
        <p className="text-base text-black capitalize">
          {selectedSubject?.teacherTitle} {selectedSubject?.teacherLastName}{" "}
          {selectedSubject?.teacherFirstName}
        </p>
      </div>

      <div className="">
        <ChooseSubjectTeacher
          teachers={teachers}
          teachersLoading={teachersLoading}
          handleSelectTeacher={setSelectedTeacher}
          selectedTeacher={selectedTeacher}
          modifySubjectTeacher={modifySubjectTeacher}
          updatingTeacher={updatingTeacher}
        />

        <p
          className="text-center text-coral_red mt-1 py-3 cursor-pointer"
          onClick={() => {
            setSelectedSubject(null);
            setSelectedTeacher(null);
          }}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};

export default ModifyClassSubject;
