import React from "react";

export default function InvoiceBilledTo({ invoice }) {
  return (
    <div className="table_wrapper">
      <div className="px-3 border-b pb-3">
        <h4 className="text-yankee_blue font-bold text-lg capitalize">
          Billing Address
        </h4>
      </div>

      {/**
        |--------------------------------------------------
        | Second row
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5">
        {/**
        |--------------------------------------------------
        | Billing email
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.billingName || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Email</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing address line one
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.billingAddressLine1 || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Address Line 1</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing address line two
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.billingAddressLine2 || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Address Line 2</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Third row
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 !pb-0 pt-5 border-none">
        {/**
        |--------------------------------------------------
        | Country
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.billingCountry || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">Country</p>
        </div>

        {/**
        |--------------------------------------------------
        | State
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.billingState || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">State</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing address line two
        |--------------------------------------------------
        */}

        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {invoice?.billingLga || "N/A"}
          </h4>

          <p className="text-sm text-greyX11">L.G.A</p>
        </div>
      </div>
    </div>
  );
}
