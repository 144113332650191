import { useCallback, useEffect, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchStudentInfo = (studentId, type) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);

  const request = useCallback(async (id, _type) => {
    setLoading(true);

    try {
      const { data } = await client.get(`/student/${id}/${_type}`);

      setDetails(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (studentId && type) request(studentId, type);
  }, [request, studentId, type]);

  return [details, loading, request];
};

export default useFetchStudentInfo;
