import { useCallback, useEffect, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchClassSubjects = (classId) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(`/classes/${classId}/subjects`);

      setData(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [classId]);

  useEffect(() => {
    if (classId) fetchData();
  }, [fetchData, classId]);

  return [loading, data, fetchData];
};
export default useFetchClassSubjects;
