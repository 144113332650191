import React from "react";
import { AiFillLeftSquare, AiFillRightSquare } from "react-icons/ai";

export default function Pagination({ pagination, setPage }) {
  if (!pagination || pagination?.pageable === 1) return null;

  const skipStart = pagination?.skip + 1;
  const skipEnd =
    pagination?.limit * pagination?.page < pagination?.totalDocuments
      ? pagination?.limit * pagination?.page
      : pagination?.totalDocuments;
  const totalDocuments = pagination?.totalDocuments || 0;

  function handleNextPage() {
    if (pagination?.page < pagination?.pageable) {
      setPage(pagination?.page + 1);
    }
  }

  function handlePreviousPage() {
    if (pagination?.page > 1) {
      setPage(pagination?.page - 1);
    }
  }

  return (
    <div className="flex items-center gap-2 pt-4 px-3">
      <small className="mr-3">
        {skipStart} - {skipEnd} of {totalDocuments}
      </small>

      <AiFillLeftSquare className="text-2xl" onClick={handlePreviousPage} />
      <small>{pagination?.page}</small>
      <AiFillRightSquare className="text-2xl" onClick={handleNextPage} />
    </div>
  );
}
