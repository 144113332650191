import { FiUsers } from "react-icons/fi";
import TableOverviewCard from "../../../../shared/components/TableOverviewCard";
import { ClipLoader } from "react-spinners";
import SelectInput from "../../../../shared/components/form/SelectInput";

const ScorecardsByTermTableOverview = ({
  obtainableScore,
  obtainedScore,
  average,
  handleSelectCalendar,
  calendars,
  selectedCalendar,
  loadingCalendars,
  reportCard,
}) => {
  return (
    <div className="table_wrapper">
      <div className="table_header !min-w-[850px]">
        <div className="pl-3">
          <h4 className="text-yankee_blue font-bold text-base capitalize">
            {`${reportCard?.category || ""} ${reportCard?.suffix || ""}`}
          </h4>

          <div className="flex items-center justify-end gap-3 mb-5 w-44 md:w-48">
            {loadingCalendars ? (
              <div className="!h-9 !xs:h-10 !ssm:h-11 w-full flex items-center justify-center">
                <ClipLoader
                  loading={loadingCalendars}
                  color={"#059F8B"}
                  size={20}
                />
              </div>
            ) : (
              <SelectInput
                placeholder={"Session"}
                className="mb-0 w-full"
                handleSelect={(e) =>
                  handleSelectCalendar(e.currentTarget.value)
                }
                options={calendars?.map((el) => ({
                  name: `${el?.session} - ${el?.term} term`,
                }))}
                value={`${selectedCalendar?.session} - ${selectedCalendar?.term} term`}
                containerStyle="border-0 !px-0 !h-8"
                inputStyle="!px-0"
              />
            )}
          </div>
        </div>

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Obtainable Score"}
          value={obtainableScore || 0}
          valueBackgroundColor={"bg-azureish_white"}
          valueColor={"text-primary"}
        />

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Score Obtained"}
          value={obtainedScore || 0}
          valueBackgroundColor={"bg-banana_mania"}
          valueColor={"text-saffron"}
        />

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Average"}
          value={average || 0}
          valueBackgroundColor={"bg-water"}
          valueColor={"text-secondary"}
        />
      </div>
    </div>
  );
};

export default ScorecardsByTermTableOverview;
