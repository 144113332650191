import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchSubjectBySchoolSubjectId(schoolSubjectId) {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState([]);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);

      const { data } = await client.get(`/curriculum/school/subject/${id}`);

      setSubject(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (schoolSubjectId) request(schoolSubjectId);
  }, [schoolSubjectId, request]);

  return [subject, loading, request];
}
