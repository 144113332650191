import { FiUsers } from "react-icons/fi";
import { AiOutlineDown } from "react-icons/ai";

import { getTotalStudents } from "../../../../shared/utils/functions";

import Loader from "../../../../shared/components/Loader";
import TableOverviewCard from "../../../../shared/components/TableOverviewCard";

const ScorecardOverview = ({
  classDetails,
  classLoading,
  selectedSubject,
  scorecardLength,
  subjectLength,
  handleShowSubjectModal,
}) => {
  return (
    <>
      {classLoading ? (
        <Loader loading={classLoading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[800px]">
          <div className="pl-2 cursor-pointer" onClick={handleShowSubjectModal}>
            {!selectedSubject ? (
              <p className="text-yankee_blue font-bold text-base underline">
                Select Subject
              </p>
            ) : null}

            {selectedSubject ? (
              <div className="flex items-center gap-2">
                <h4 className="text-yankee_blue font-bold text-base capitalize underline">
                  {selectedSubject?.name}
                </h4>

                <AiOutlineDown size={12} />
              </div>
            ) : null}
          </div>

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || "-"}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Scorecards"}
            value={scorecardLength || "-"}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Subjects"}
            value={subjectLength || "-"}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default ScorecardOverview;
