import { FiEdit3, FiUsers } from "react-icons/fi";

import Loader from "../../../../shared/components/Loader";
import TableOverviewCard from "../../../../shared/components/TableOverviewCard";
// import { AiFillEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

const ClassDetailsOverview = ({ loading, classDetails }) => {
  const navigate = useNavigate();
  const { schoolId, classId } = useParams();

  let maleStudents = classDetails?.maleStudents || 0;
  let femaleStudents = classDetails?.femaleStudents || 0;

  const teacher = `${classDetails?.teacherTitle || ""} ${
    classDetails?.teacherFirstName || ""
  } ${classDetails?.teacherLastName || ""}`;

  function handleEditClassTeacher() {
    navigate(`/${schoolId}/classes/${classId}?action=class-teacher`);
  }

  return (
    <>
      {loading ? (
        <Loader loading={loading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[850px]">
          <div className="px-3 cursor-pointer" onClick={handleEditClassTeacher}>
            <h4 className="text-yankee_blue font-bold text-base capitalize">
              {teacher}
            </h4>

            <div className="flex items-center gap-2">
              <p className="text-sm text-greyX11">Class Teacher</p>
              <FiEdit3 className="text-sm text-greyX11" />
            </div>
          </div>

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={maleStudents + femaleStudents}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Female Students"}
            value={femaleStudents}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Male Students"}
            value={maleStudents}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />
        </div>
      </div>
    </>
  );
};

export default ClassDetailsOverview;
