import { createSlice } from "@reduxjs/toolkit";

const name = "notification";

export const notificationSlice = createSlice({
  name,
  initialState: {
    totalUnread: 0,
  },
  reducers: {
    setTotalUnreadNotifications: (state, action) => {
      state.totalUnread = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTotalUnreadNotifications } = notificationSlice.actions;
const { reducer } = notificationSlice;

export { reducer, name };
