import React from "react";
import ClassTable from "../../../shared/components/archives/reportCards/ClassTable";
import useFetchSchoolClasses from "../../../shared/api/classes/useFetchSchoolClasses";
import { useSelector } from "react-redux";
import { selectCalendar } from "../../../redux/slices/school/selectors";

export default function ClassListForReportcards() {
  const calendar = useSelector(selectCalendar);

  const [juniorClasses, seniorClasses, loading] = useFetchSchoolClasses(
    calendar?.session
  );

  function getTotalStudents(item) {
    if (!item) return 0;

    return item?.maleStudents + item?.femaleStudents;
  }

  function getTeacherDetails(item) {
    if (!item?.teacherFirstName && !item?.teacherLastName) return "N/A";

    const teacherName = `${item?.teacherFirstName || ""} ${
      item?.teacherLastName || ""
    }`;

    return teacherName;
  }

  return (
    <div>
      <div className="page">
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Classes</h1>
        </div>

        {/* Junior Calsses */}
        <ClassTable
          loading={loading}
          classes={juniorClasses}
          getTeacherDetails={getTeacherDetails}
          getTotalStudents={getTotalStudents}
          label={"Junior Classes"}
        />

        {/* Senior Classes */}
        <ClassTable
          loading={loading}
          classes={seniorClasses}
          getTeacherDetails={getTeacherDetails}
          getTotalStudents={getTotalStudents}
          label={"Senior Classes"}
        />
      </div>
    </div>
  );
}
