import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";
import ChooseSubjects from "./ChooseSubjects";
import useCreateStudentReportCard from "../../../../shared/api/cards/useCreateStudentReportCard";

const NewReportCard = ({
  student,
  fetchReportCard,
  handleCancelNewReportcard,
  action,
  previousSubjects,
}) => {
  const { classId, studentId } = useParams();
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  const calendar = useSelector(selectCalendar);

  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [createReportCardRequest, loading] = useCreateStudentReportCard();

  useEffect(() => {
    if (previousSubjects?.length) {
      setSelectedSubjects(previousSubjects);
    }
  }, [previousSubjects]);

  useEffect(() => {
    if (!student?.department) {
      setFilteredSubjects(subjects);
    } else {
      const filtered = subjects?.filter(
        (el) =>
          el?.department?.toLowerCase() === student?.department ||
          el?.department?.toLowerCase() === "general"
      );

      setFilteredSubjects(filtered);
      setSelectedSubjects([]);
    }
  }, [setSelectedSubjects, student?.department, subjects]);

  function handleSelect(params) {
    if (loading) return;

    const foundIndex = selectedSubjects.findIndex(
      (element) => element?.subjectId === params?.subjectId
    );

    if (foundIndex >= 0) {
      setSelectedSubjects(
        selectedSubjects.filter((el) => el?.subjectId !== params?.subjectId)
      );
    } else {
      setSelectedSubjects([
        ...selectedSubjects,
        {
          subjectId: params?.subjectId,
          schoolSubjectId: params?.schoolSubjectId,
          classSubjectId: params?.classSubjectId,
          name: params?.name,
        },
      ]);
    }
  }

  async function createReportCard() {
    if (!calendar) return;

    const isSuccess = await createReportCardRequest({
      studentId,
      subjects: selectedSubjects,
      session: calendar?.session,
      term: calendar?.term,
      classId,
      calendarId: calendar?.calendarId,
    });
    if (isSuccess) {
      setSelectedSubjects([]);
      await fetchReportCard();
      handleCancelNewReportcard();
    }
  }

  if (action !== "new-reportcard") return null;

  return (
    <div>
      <h2 className="side_page_title">Create Report Card</h2>

      <div className="mb-7">
        <p className="text-xs text-yankee_blue font-semibold mb-2">
          <span className="font-normal text-morning_blue">Session:</span>{" "}
          {calendar?.session}
        </p>

        <p className="text-xs text-yankee_blue font-semibold mb-2 capitalize">
          <span className="font-normal text-morning_blue ">Term:</span>{" "}
          {calendar?.term} term
        </p>

        {student?.department ? (
          <p className="text-xs text-yankee_blue font-semibold mb-2">
            <span className="font-normal text-morning_blue">Department:</span>{" "}
            {student?.department} department
          </p>
        ) : null}

        <p className="text-xs text-yankee_blue font-semibold mb-2">
          <span className="font-normal text-morning_blue">
            Selected Subjects:
          </span>{" "}
          {selectedSubjects?.length || 0}
        </p>
      </div>

      <ChooseSubjects
        subjects={filteredSubjects}
        handleSelectSubject={handleSelect}
        selectedSubjects={selectedSubjects}
        subjectLoading={subjectLoading}
        title={"Select subjects for student"}
        createReportCard={createReportCard}
        loading={loading}
      />
    </div>
  );
};
export default NewReportCard;
