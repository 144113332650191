import { useCallback, useState } from "react";
import handleApiError from "../../hooks/handleApiError";
import client from "../client";
import { handleToast } from "../../utils/functions";

export default function useUpdateSchool() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload, schoolId) => {
    try {
      setLoading(true);

      const { data } = await client.patch(`/school/${schoolId}`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      handleToast(data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return [request, loading];
}
