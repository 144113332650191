import { FiUsers } from "react-icons/fi";
import { getTotalStudents } from "../../../utils/functions";

import Loader from "../../Loader";
import TableOverviewCard from "../../TableOverviewCard";
import SelectInput from "../../form/SelectInput";

const ReportCardOverview = ({
  classDetails,
  classLoading,
  subjectLength,
  reportCardsLength,
  handleSelectCalendar,
  selectedTerm,
  currentCalendar,
}) => {
  return (
    <>
      {classLoading ? (
        <Loader loading={classLoading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[800px]">
          <div className="pl-3">
            <h4 className="text-yankee_blue font-bold text-base capitalize">
              {`${currentCalendar?.session || ""}`}
            </h4>

            <div className="flex items-center justify-end gap-3 mb-5 w-32">
              <SelectInput
                placeholder={"Term"}
                className="mb-0 w-full"
                handleSelect={(e) =>
                  handleSelectCalendar(e.currentTarget.value)
                }
                options={[
                  { name: "First Term", value: "first" },
                  { name: "Second Term", value: "second" },
                  { name: "Third Term", value: "third" },
                ]}
                value={selectedTerm}
                containerStyle="border-0 !px-0 !h-8"
                inputStyle="!px-0"
              />
            </div>
          </div>

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || "-"}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Report Cards"}
            value={reportCardsLength}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Subjects"}
            value={subjectLength || "-"}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default ReportCardOverview;
