import { useCallback, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";
import { handleToast } from "../../utils/functions";

export default function useRemoveSubjectFromSchoolCurriculum() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (schoolId, payload) => {
    try {
      setLoading(true);

      const { data } = await client.post(
        `/curriculum/school/${schoolId}/removeSubject`,
        payload
      );

      handleToast(data?.message);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return [loading, request];
}
