import { useSelector } from "react-redux";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import WeekRow from "./WeekRow";
import IMAGES from "../../../../shared/constant/images";
import Loader from "../../../../shared/components/Loader";
import useFetchStudentBasicInfo from "../../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentAttendance from "../../../../shared/api/classes/attendance/useFetchStudentAttendance";

const StudentAttendance = ({ closeSideModal, studentId }) => {
  const calendar = useSelector(selectCalendar);

  const [loading, student] = useFetchStudentBasicInfo(studentId);

  const { data: attendance, loading: loadingAttendance } =
    useFetchStudentAttendance(studentId, calendar?.calendarId);

  function filterByWeek(weekNumber) {
    if (!attendance || !attendance?.length) return;

    const [filter] = attendance?.filter((el) => el?.week === weekNumber);
    return filter;
  }

  if (!studentId) return null;
  if (loading) return <Loader loading={loading} />;

  return (
    <div>
      <div className="border h-[200px] flex items-center justify-center mb-3">
        <img
          src={IMAGES.studentAvatar}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <h3 className="font-bold text-yankee_blue mb-3 text-lg capitalize text-center">
        {student?.lastName || ""} {student?.firstName || ""}
      </h3>

      {loadingAttendance ? <Loader loading={loadingAttendance} /> : null}

      <WeekRow label={"Week One"} attendance={filterByWeek(1)} />
      <WeekRow label={"Week Two"} attendance={filterByWeek(2)} />
      <WeekRow label={"Week Three"} attendance={filterByWeek(3)} />
      <WeekRow label={"Week Four"} attendance={filterByWeek(4)} />
      <WeekRow label={"Week Five"} attendance={filterByWeek(5)} />
      <WeekRow label={"Week Six"} attendance={filterByWeek(6)} />
      <WeekRow label={"Week Seven"} attendance={filterByWeek(7)} />
      <WeekRow label={"Week Eight"} attendance={filterByWeek(8)} />
      <WeekRow label={"Week Nine"} attendance={filterByWeek(9)} />
      <WeekRow label={"Week Ten"} attendance={filterByWeek(10)} />
      <WeekRow label={"Week Eleven"} attendance={filterByWeek(11)} />
      <WeekRow label={"Week Twelve"} attendance={filterByWeek(12)} />
      <WeekRow label={"Week Thirteen"} attendance={filterByWeek(13)} />
      <WeekRow label={"Week Fourteen"} attendance={filterByWeek(14)} />
      <WeekRow label={"Week Fifteen"} attendance={filterByWeek(15)} />

      <div className="py-3 px-3 mt-auto">
        <button
          className="bg-transparent text-coral_red h-10 w-full rounded-md text-sm font-semibold"
          onClick={closeSideModal}
        >
          Back to list
        </button>
      </div>
    </div>
  );
};
export default StudentAttendance;
