import { getGrade, getTotalForScorecard } from "../../../utils/functions";

const ArchivedScorecardsTableBody = ({ data }) => {
  if (!data?.length) return null;

  return (
    <>
      {data?.map((card, i) => (
        <div
          className={"grid grid-cols-8 border-b hover:active_table_row"}
          key={i}
        >
          <div className="col-span-3 border-r p-3">
            <p className="text-independence text-sm capitalize">{card?.name}</p>
          </div>

          <div className="border-r p-3">
            <p className="text-independence text-sm">
              {card?.continuousAssessmentOneScore}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm">
              {card?.continuousAssessmentTwoScore}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm capitalize">
              {card?.examScore}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm">
              {getTotalForScorecard(card)}
            </p>
          </div>

          <div className="p-3 border-r">
            <p className="text-independence text-sm">
              {getGrade(getTotalForScorecard(card))}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default ArchivedScorecardsTableBody;
