import { NavLink, useParams } from "react-router-dom";

const ClassNavigationBar = ({ activeIndex }) => {
  const { classId, schoolId } = useParams();

  return (
    <div className="table_wrapper">
      <div className="main_content_header">
        {/* <NavLink to={`/${schoolId}/classes`}>
          <button className="main_content_header_btn">Classes</button>
        </NavLink> */}

        {/* <div className="main_content_header_line" /> */}

        <NavLink to={`/${schoolId}/classes/${classId}`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 0 ? "main_content_header_btn_active" : ""
            }`}
          >
            Students
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink to={`/${schoolId}/classes/${classId}/students/new`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 1 ? "main_content_header_btn_active" : ""
            }`}
          >
            New Student
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink to={`/${schoolId}/classes/${classId}/subjects`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 2 ? "main_content_header_btn_active" : ""
            }`}
          >
            Subjects
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink to={`/${schoolId}/classes/${classId}/attendance`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 3 ? "main_content_header_btn_active" : ""
            }`}
          >
            Attendance
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink to={`/${schoolId}/classes/${classId}/scorecards`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 4 ? "main_content_header_btn_active" : ""
            }`}
          >
            Scorecards
          </button>
        </NavLink>

        <div className="main_content_header_line" />

        <NavLink to={`/${schoolId}/classes/${classId}/reportcards`}>
          <button
            className={`main_content_header_btn ${
              activeIndex === 5 ? "main_content_header_btn_active" : ""
            }`}
          >
            Report Cards
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default ClassNavigationBar;
