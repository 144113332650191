import { FiUsers } from "react-icons/fi";
import Loader from "../../Loader";
import TableOverviewCard from "../../TableOverviewCard";
import { getTotalStudents } from "../../../utils/functions";

const SubjectOverview = ({ classDetails, classLoading }) => {
  return (
    <>
      {classLoading ? (
        <Loader loading={classLoading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header">
          <div className="px-3">
            <h4 className="text-yankee_blue font-bold text-base capitalize">
              {classDetails?.teacherLastName || ""}{" "}
              {classDetails?.teacherFirstName || ""}
            </h4>

            <p className="text-sm text-greyX11">Class Teacher</p>
          </div>

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || 0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Male"}
            value={classDetails?.maleStudents || 0}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Female"}
            value={classDetails?.femaleStudents || 0}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default SubjectOverview;
