import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUserDetails } from "../../../redux/slices/auth/slice";
import { handleToast } from "../../utils/functions";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useRegisterSchool() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const { data } = await client.post(`/auth/register/school`, payload);

        localStorage.setItem("token", data?.data?.token);
        localStorage.setItem("state", data?.data?.state);
        localStorage.removeItem("userId");
        dispatch(setUserDetails(data?.data?.user));

        setLoading(false);

        handleToast(data?.message);
        // navigate(`/${data?.data?.user?.schoolId}/dashboard`);
        navigate(`/register/${data?.data?.user?.schoolId}/plan`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, navigate]
  );

  return [request, loading];
}
