import Loader from "./Loader";

const ChooseTeacher = ({
  teachers,
  handleSelectTeacher,
  selectedTeacher,
  teachersLoading,
}) => {
  return (
    <div className="bg-white pb-4 rounded-md">
      <h5 className="text-independence text-base mb-3">Teachers</h5>

      {teachersLoading ? <Loader loading={teachersLoading} /> : null}

      <div className="">
        {!teachersLoading &&
          teachers?.map((teacher, i) => (
            <div
              key={i}
              className={`flex items-center px-3 mb-3 h-12 rounded-sm cursor-pointer bg-cultured ${
                selectedTeacher?.userId === teacher?.userId
                  ? "border-primary border bg-white"
                  : ""
              }`}
              onClick={() => handleSelectTeacher(teacher)}
            >
              <p
                className={`text-sm font-semibold capitalize ${
                  selectedTeacher?.userId === teacher?.userId
                    ? "text-primary"
                    : "text-yankee_blue"
                }`}
              >
                {teacher?.lastName} {teacher?.firstName}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChooseTeacher;
