import React from "react";
import {
  // ClipLoader,
  BeatLoader,
  // SyncLoader,
  // FadeLoader,
  // ScaleLoader,
} from "react-spinners";

export default function Loader({
  loading,
  className,
  color = "#059F8B",
  size = 10,
}) {
  return (
    <div
      className={`min-h-[80px] flex justify-center items-center ${className}`}
    >
      <BeatLoader loading={loading} color={color} size={size} />
    </div>
  );
}
