// import { create } from "apisauce";
import axios from "axios";

const client = axios.create({
  // baseURL: "http://localhost:8080/api/v1",
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  },
  timeout: 90000,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage?.getItem("token") || "";

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default client;
