import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchSchoolTransactions(schoolId) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await client.get(`/payment/transactions/school/${id}`);

      setData(response.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (schoolId) request(schoolId);
  }, [request, schoolId]);

  return { request, loading, data };
}
