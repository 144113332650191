import { useCallback, useState } from "react";

import { handleToast } from "../../utils/functions";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useCreateSubClass() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload) => {
    setLoading(true);

    try {
      const { data } = await client.post(`/classes`, payload);

      handleToast(data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return [request, loading];
}
