import { NavLink, useParams } from "react-router-dom";

const TeacherNavigationBar = ({ active }) => {
  const { schoolId } = useParams();

  return (
    <div className="table_wrapper">
      <div className="main_content_header !min-w-fit">
        <NavLink to={`/${schoolId}/teachers`}>
          <button
            className={`main_content_header_btn ${
              active === 1 ? "main_content_header_btn_active" : ""
            } `}
          >
            All Teachers
          </button>
        </NavLink>

        <NavLink to={`/${schoolId}/teachers/new`}>
          <button
            className={`main_content_header_btn ${
              active === 2 ? "main_content_header_btn_active" : ""
            } `}
          >
            New Teacher
          </button>
        </NavLink>

        <NavLink to={`/${schoolId}/teachers/invites`}>
          <button
            className={`main_content_header_btn ${
              active === 3 ? "main_content_header_btn_active" : ""
            } `}
          >
            Pending Invites
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default TeacherNavigationBar;
