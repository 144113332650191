import React from "react";
import { useSelector } from "react-redux";

import { selectSchoolDetails } from "../../../redux/slices/school/selectors";
import TransactionsTable from "../../../shared/components/settings/billing/TransactionsTable";
import useFetchSchoolTransactions from "../../../shared/api/payment/useFetchSchoolTransactions";
import BillingNavigationBar from "../../../shared/components/settings/billing/BillingNavigationBar";

export default function Transactions() {
  // const { schoolId } = useParams();
  const school = useSelector(selectSchoolDetails);
  const { data: transactions } = useFetchSchoolTransactions(school?.schoolId);

  return (
    <div>
      <div className="page">
        {/**
        |--------------------------------------------------
        | Page title
        |--------------------------------------------------
        */}
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Transactions</h1>
        </div>

        {/**
        |--------------------------------------------------
        | Navigation bar
        |--------------------------------------------------
        */}
        <BillingNavigationBar activeIndex={1} />

        {/**
        |--------------------------------------------------
        | Main content
        |--------------------------------------------------
        */}
        <div className="main_content !pt-0 !pb-0 mb-10">
          {/**
            |--------------------------------------------------
            | Billing Overview
            |--------------------------------------------------
            */}
          <TransactionsTable data={transactions} />
        </div>
      </div>
    </div>
  );
}
