import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchTeachers = (schoolId, page, setPagination) => {
  const [loading, setLoading] = useState(false);

  const [teachers, setTeachers] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(
        `/school/${schoolId}/teachers?page=${page}`
      );

      setTeachers(data?.data);
      if (data?.pagination && setPagination) setPagination(data?.pagination);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [schoolId, page, setPagination]);

  useEffect(() => {
    if (schoolId) request();
  }, [request, schoolId]);

  return [loading, teachers];
};

export default useFetchTeachers;
