import { createSlice } from "@reduxjs/toolkit";

const name = "school";

export const schoolSlice = createSlice({
  name,
  initialState: {
    schoolDetails: null,
    calendar: null,
    juniorSubjects: [],
    seniorSubjects: [],
  },
  reducers: {
    setSchoolData: (state, action) => {
      state.schoolDetails = action.payload;
    },
    setCalendar: (state, action) => {
      state.calendar = action.payload;
    },
    setJuniorSubjects: (state, action) => {
      state.juniorSubjects = action.payload;
    },
    setSeniorSubjects: (state, action) => {
      state.seniorSubjects = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSchoolData,
  setCalendar,
  setJuniorSubjects,
  setSeniorSubjects,
} = schoolSlice.actions;
const { reducer } = schoolSlice;

export { reducer, name };
