const ScorecardsByTermTableHeader = () => {
  return (
    <div className="grid grid-cols-9 border-b">
      <div className="col-span-3 border-r p-3">
        <h5 className="font-semibold text-sm">Subject</h5>
      </div>

      <div className="border-r p-3">
        <h5 className="font-semibold text-sm">C.A 1</h5>
      </div>

      <div className="p-3 border-r">
        <h5 className="font-semibold text-sm">C.A 2</h5>
      </div>

      <div className="p-3 border-r">
        <h5 className="font-semibold text-sm">Exam</h5>
      </div>

      <div className="p-3 border-r">
        <h5 className="font-semibold text-sm">Total</h5>
      </div>

      <div className="p-3 border-r">
        <h5 className="font-semibold text-sm">Grade</h5>
      </div>

      <div className="p-3">
        <h5 className="font-semibold text-sm"> </h5>
      </div>
    </div>
  );
};

export default ScorecardsByTermTableHeader;
