const NotificationDetails = ({ notification }) => {
  return (
    <div className="p-10">
      <div className="mb-10">
        <p className="text-sm text-dark_electric_blue">
          {new Date(notification?.createdAt)?.toDateString()}
        </p>

        <h2 className="text-center text-xl uppercase">
          {notification?.subject}
        </h2>
      </div>

      <p className="text-sm text-police_blue">{notification?.message}</p>
    </div>
  );
};
export default NotificationDetails;
