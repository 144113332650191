import { useSelector } from "react-redux";
import { selectSchoolDetails } from "../../../redux/slices/school/selectors";
import { NavLink, useParams } from "react-router-dom";

const SchoolSubjectsStatus = () => {
  const { schoolId } = useParams();
  const school = useSelector(selectSchoolDetails);

  if (
    !school ||
    (school?.hasSortJuniorCurriculum && school?.hasSortSeniorCurriculum)
  ) {
    return null;
  }

  return (
    <div>
      {school &&
      (!school?.hasSortJuniorCurriculum || !school?.hasSortSeniorCurriculum) ? (
        <div className="grid sm:grid-cols-2 gap-5 mb-10">
          {!school?.hasSortJuniorCurriculum ? (
            <NavLink to={`/${schoolId}/settings/curriculum/junior?action=sort`}>
              <div className="bg-pale_pink py-2 px-3 cursor-pointer rounded-sm">
                <h6 className="text-base text-coral_red mb-1">
                  Sort Junior Subjects
                </h6>

                <p className="text-sm text-congo_pink">
                  Select subjects that will be taught accross your junior
                  classes
                </p>
              </div>
            </NavLink>
          ) : null}

          {!school?.hasSortSeniorCurriculum ? (
            <NavLink to={`/${schoolId}/settings/curriculum/senior?action=sort`}>
              <div className="bg-pale_pink py-2 px-3 cursor-pointer rounded-sm">
                <h6 className="text-base text-coral_red mb-1">
                  Sort Senior Subjects
                </h6>

                <p className="text-sm text-congo_pink">
                  Select subjects that will be taught accross your senior
                  classes
                </p>
              </div>
            </NavLink>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default SchoolSubjectsStatus;
